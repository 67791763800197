import { Icon } from "@iconify/react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultImage from "../../../../Assets/images/default.png";
import { getRequest, postRequest } from "../../../../Helper/httpProtocol";
import Comment from "../../Forum/Comment";
import { Dropdown } from "flowbite-react";
export default function Index({ id, answer, postedTime, userId, EditHandle }) {
  const user = useSelector((state) => state.user);
  const [upIcon, setUpIcon] = useState(false);
  const [downIcon, setDownIcon] = useState(false);
  // const [userData, setUserData] = useState();
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [comment, setComment] = useState(false);
  const [upVote, setUpVote] = useState(0);
  const [downVote, setDownVote] = useState(0);
  const [comments, setComments] = useState([]);

  const getComment = () => {
    getRequest("forum/comment", { answerId: id })
      .then((payload) => {
        setComments(payload.data.data);
      })
      .catch(async (err) => {
        toast(err.response.data.message);
        await navigate("/login");
      });
  };
  useEffect(() => {
    getComment();
  }, []);


  const getUpVote = async () => {
    await getRequest("forum/getupvote", { questionId: id }).then((res) => {
      setUpVote(res.data.data);
    });
  };
  const getDownVote = async () => {
    await getRequest("forum/getdownvote", { questionId: id }).then((res) => {
      setDownVote(res.data.data);
    });
  };

  useMemo(() => {
    getUpVote();
    getDownVote();
  }, [upVote, downVote, id]);
  const handleIconLike = async () => {
    console.log("clicked on upVote")
    await postRequest("forum/createupvote", { questionId: id }, { token })
      .then((res) => {
        toast(res.data.message);
        getUpVote();
        setUpIcon(!upIcon);
        setUpVote(upVote.length + (upIcon ? -1 : 1));

        if (upVote.userId === user?._id) {

        }
      })
      .catch(async (err) => {
        toast(err.response.data.message);
        await navigate("/login");
      });
  };
  const handleIconUnLike = async () => {
    console.log("Clicked on downvote")
    await postRequest("forum/createdownvote", { questionId: id }, { token })
      .then((res) => {
        toast(res.data.message);
        getDownVote();
        setDownIcon(!downIcon);
        setDownVote(downVote.length + (upIcon ? -1 : 1));
        console.log(downVote);
      })
      .catch(async (err) => {
        toast(err.response.data.message, "here");

      });



  };

  return (
    <>
      <div className="questions-card ">
        {" "}
        <div className={`w-100  ${comment && "down-line-1"}`}>
          <div className="d-flex  w-100">
            <div>
              {userId && userId?.photoURL ? (
                <img src={userId?.photoURL} alt="" />
              ) : (
                <img src={DefaultImage} alt="" />
              )}
            </div>
            <div className="d-flex ms-3 justify-between m-1 p-2 me-0 w-100 bg-light-purple radius-5">
              <div>
                {" "}
                <p className="dark-color answer-text">{answer} </p>
                <div className="icon-div">
                  <div className="d-flex" onClick={handleIconLike}>
                    {" "}
                    {upIcon ? (
                      <Icon icon="ph:arrow-fat-up-fill" />
                    ) : (
                      <Icon icon="ph:arrow-fat-up-light" />
                    )}{" "}
                    <span>
                      {upVote.length}
                    </span>
                  </div>{" "}
                  <div className="d-flex" onClick={handleIconUnLike}>
                    {" "}
                    {downIcon ? (
                      <Icon icon="ph:arrow-fat-down-fill" className="ms-3" />
                    ) : (
                      <Icon icon="ph:arrow-fat-down-light" className="ms-3" />
                    )}{" "}
                    <span>
                      {downVote.length}
                    </span>
                  </div>{" "}
                  <div className="d-flex">
                    <Icon
                      icon="bxs:share"
                      onClick={() => setComment(!comment)}
                      className="ms-3"
                    />{" "}
                    <span>{comments?.length}</span>
                  </div>
                </div>
              </div>
              <div className="action-div">
                <div>
                  {userId?._id === user?._id && (
                    <>
                      {" "}
                      <Dropdown
                        arrowIcon={false}
                        inline
                        className="profile-drop"
                        label={<Icon icon="bi:three-dots" />}
                      >
                        <Dropdown.Item onClick={EditHandle}>Edit</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item>Delete</Dropdown.Item>
                      </Dropdown>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {comment ? <Comment answerId={id} /> : null}
    </>
  );
}
