import React, { useEffect } from "react";
import DefaultImage from "../../Assets/images/default.png";
import ProfileCard from "../../Components/Card/profile-card";
import QuestionsCard from "../../Components/Card/question-card";
import SidebarProfile from "../../Components/Sidebar/SidebarProfile";
import Header from "../../Layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../Helper/httpProtocol";
export default function Index() {
  const dispatch = useDispatch();
  const userCreatedForum = useSelector((state) => state.userCreatedForum);
  const userCreatedForumTotal = useSelector(
    (state) => state.userCreatedForumTotal
  );

  const getMyQuestion = async () => {
    const token = await localStorage.getItem("token");
    getRequest("forum/usercreatedforum?take=5&skip=0", { token })
      .then(async (res) => {
        await dispatch({
          type: "userCreatedForum",
          userCreatedForum: res.data.data,
        });
        await dispatch({
          type: "userCreatedForumTotal",
          userCreatedForumTotal: res.data.total,
        });
      })
      .catch((error) => console.log(error));
  };

  const getDraftForum = async () => {
    const token = await localStorage.getItem("token");
    getRequest("forum/getalldraft?take=5&skip=0", { token })
      .then(async (res) => {
        await dispatch({ type: "forumsDraft", forumsDraft: res.data.data });
        await dispatch({
          type: "totalDraftForums",
          totalDraftForums: res.data.total,
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getMyQuestion();
  }, []);
  return (
    <div className="container-fluid">
      <div className="">
        <Header />
        <div className="container ">
          <div className="d-flex">
            <div className="d-none d-lg-block" style={{ width: " 300px" }}>
              <SidebarProfile />
            </div>
            <div className="w-100 pt-52  mt-3 mb-4">
              <div className="bg-white p-3">
                <ProfileCard />
              </div>
              <h4 className="heading-border radius-5">
                Your Questions
                <span className="light-color">
                  {" "}
                  {`(${userCreatedForumTotal})`}
                </span>{" "}
              </h4>{" "}
              {userCreatedForum?.map((item, index) => {
                return (
                  <QuestionsCard
                    key={index}
                    src={item?.userId?.photoURL ?? DefaultImage}
                    title={item?.title}
                    description={item?.description}
                    userId={item?.userId}
                    category={item?.category}
                    subCategory={item?.subCategory}
                    id={item?._id}
                    status={item?.status}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
