import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  backendCategories,
  frontEndCategories,
  machineLearningCategories,
  mainCategories,
  mobileApplicationDevelopmentCategories,
} from "../../../Config/Consent";
import { getRequest, postRequest } from "../../../Helper/httpProtocol";
import { InputTags } from "react-bootstrap-tagsinput";
import Post from "../../../Layout/Post";
import { Breadcrumb, Button, Card, TextInput } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { Input } from "postcss";
export default function Index() {
  const [data, setData] = useState({});
  const [category, setCategory] = useState();
  const [tags, setTags] = useState([]);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const handleChange = (event) => {
    data[event.target.name] = event.target.value;
    setData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    postRequest("forum/create", { ...data, tags, category }, { token })
      .then(async (res) => {
        toast(res.data.message);
        await navigate("/");
      })
      .catch((err) => toast(err.response.data.message));
  };
  return (
    
      <div class="col-lg-8 col-md-8 mt-4 ">
      <Breadcrumb aria-label="Default breadcrumb example" className="p-0">
        <Breadcrumb.Item className="p-0" icon={HiHome}>
          <span className="cursor-pointer">
            <Link className="no-underline" to={"/"}>
              Home
            </Link>
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span className="cursor-pointer">
            <Link className="no-underline" to={"/create-topic"}>
              New Topic
            </Link>
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="w-full border-primary">
        <div>
          <input
            name={"title"}
            type="text"
            id="input-gray"
            className="form-control"
            placeholder="Enter Topic Title"
            required={true} 
            onChange={handleChange}
          />
          
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 gap-4">
            <select
              name="category"
              id="category"
              className="form-control"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled selected>
                Select Category
              </option>
              {mainCategories.map((e) => {
                return <option value={e.name}>{e.name}</option>;
              })}
            </select>
          </div>
          <div className="col-lg-6 col-md-6 pt-4 pt-lg-0">
            <select
              name="subCategory"
              id="subCategory"
              className="form-control"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Select Subcategory
              </option>
              {category == "Frontend"
                ? frontEndCategories.map((e) => {
                    return <option value={e}>{e}</option>;
                  })
                : category == "Backend"
                ? backendCategories.map((e) => {
                    return <option value={e}>{e}</option>;
                  })
                : category == "Machine Learning"
                ? machineLearningCategories.map((e) => {
                    return <option value={e}>{e}</option>;
                  })
                : category == "Artificial Intelligence"
                ? machineLearningCategories.map((e) => {
                    return <option value={e}>{e}</option>;
                  })
                : category == "Mobile Application"
                ? mobileApplicationDevelopmentCategories.map((e) => {
                    return <option value={e}>{e}</option>;
                  })
                : ""}
            </select>
          </div>
        </div>
        <div>
          <textarea
            name="description"
            id="desc"
            placeholder="Description"
            className="form-control"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="input-group ">
          <InputTags className={"form-control"} values={tags} onTags={(value) => setTags(value.values)}  />
          <button
            className="btn btn-outline-primary "
            type="button"
            
            data-testid="button-clearAll"
            onClick={() => {
              setTags([]);
            }}
          >
            Clear
          </button>
        </div>
        <div className="">
          <Button onClick={handleSubmit} className="">
            Post
          </Button>
        </div>
      </Card>{" "}
 
    </div>
    
  );
}
