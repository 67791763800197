import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import '../../Style//Custom/custom.css';
import '../../Style/style.css';
import RegistrationForm from '../../Components/Form/Registration';

export default function Index() {
    return (
      <div className="container-fluid account-screen">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 ">
            <RegistrationForm referenceId={"register"} />
     
          </div>
        </div>
      
      </div>
    );
}
