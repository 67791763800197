import React, { useState } from "react";
import { postRequest } from "../../../Helper/httpProtocol";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import { auth } from "../../../Config/Firebase";
import { GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, signInWithPopup } from "firebase/auth";
import { Button, Label } from "flowbite-react";

const Index = ({ referenceId }) => {
  const [data, setData] = useState({ referenceId });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleProviderLogin = async (provider, providerName) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const providerData = user.providerData?.map((e) => ({
        ...e,
        firstName: e.displayName.split(" ")[0],
        lastName: e.displayName.split(" ")[1],
        provider: providerName,
        providerId: e.uid,
      }));
      const response = await postRequest(`auth/${providerName}`, providerData[0]);
      localStorage.setItem("token", response.data.token);
      dispatch({ type: "token", token: response.data.token });
      navigate("/");
    } catch (error) {
      toast(error.message);
    }
  };

  const signInWithGoogle = () => handleProviderLogin(new GoogleAuthProvider(), "google");
  const signInWithFacebook = () => handleProviderLogin(new FacebookAuthProvider(), "facebook");
  const signInWithGitHub = () => handleProviderLogin(new GithubAuthProvider(), "github");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (referenceId === "login") {
        const response = await postRequest("auth/signin", data);
        localStorage.setItem("token", response.data.token);
        dispatch({ type: "token", token: response.data.token });
        navigate("/");
        // window.location.reload()
        toast(response.data.message);
      } else {
        if (data.password === data.confirmPassword) {
          const response = await postRequest("auth/signup", data);
          localStorage.setItem("token", response.data.token);
          dispatch({ type: "token", token: response.data.token });
          navigate("/");
          // window.location.reload()

          toast(response.data.message);
        } else {
          toast("Password does not match");
        }
      }
    } catch (err) {
      toast(err.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div className="mt-3">
      <div className="post-card">
        <div className={`login-card px-lg-5 ${referenceId === "login" ? "login-card" : ""}`}>
          <h1>JS PEDIA</h1>
          <h5 className="mb-3">{referenceId === "login" ? "Login" : "Sign up"}</h5>
          <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
            {referenceId === "login" ? (
              <>
                <div>
                  <Label htmlFor="userName" value="Your Username" />
                  <input
                    className="form-control"
                    id="userName"
                    type="text"
                    name="userName"
                    placeholder="Sarah"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <Label htmlFor="password" value="Your Password" />
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    name="password"
                    placeholder="*****************"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="align-right">
                  <Link to="/CreateAccount" className="text-decoration-none">
                    <p className="m-0">Forgot password?</p>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-12 mt-3 col-lg-6">
                    <Label htmlFor="firstName" value="First Name*" />
                    <input
                      type="text"
                      placeholder="Sarah"
                      name="firstName"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3 col-lg-6">
                    <Label htmlFor="lastName" value="Last Name" />
                    <input
                      id="lastName"
                      type="text"
                      placeholder="John"
                      name="lastName"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Label htmlFor="userName" value="User Name*" />
                    <input
                      id="userName"
                      type="text"
                      className="form-control"
                      placeholder="Jhon24"
                      name="userName"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Label htmlFor="email" value="Email*" />
                    <input
                      id="email"
                      type="text"
                      className="form-control"
                      placeholder="Example@gmail.com"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3 col-lg-6">
                    <Label htmlFor="password" value="Password*" />
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      placeholder="*****************"
                      name="password"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3 col-lg-6">
                    <Label htmlFor="confirmPassword" value="Confirm Password*" />
                    <input
                      id="confirmPassword"
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      placeholder="*****************"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
            <Button disabled={loading} className="bg-purple mt-3" type="submit">
              {referenceId === "login" ? "Login" : "Sign up"}
            </Button>
          </form>
          <div className="center-div my-3">
            <p>{referenceId === "login" ? "Or continue with" : "Or Sign up with"}</p>
            <div className="d-flex">
              <Icon icon="bi:facebook" onClick={signInWithFacebook} className="facebook-icon" />
              <Icon icon="flat-color-icons:google" onClick={signInWithGoogle} className="twitte-icon" />
              <Icon icon="bi:github" onClick={signInWithGitHub} className="github-icon" />
            </div>
            <div className="my-3">
              {referenceId === "login" ? (
                <>
                  Don’t have an account?{" "}
                  <Link to="/CreateAccount" className="text-decoration-none text-primary">
                    Sign up
                  </Link>
                </>
              ) : (
                <Link to="/" className="text-decoration-none text-primary">
                  <small>
                    <Icon icon="formkit:arrowleft" className="d-inline" />
                    Back to Home
                  </small>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
