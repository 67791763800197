import React from "react";
import Header from "../../Layout/Header";
import { Link } from "react-router-dom";

export default function Index() {
  return (
    <div className="container-fluid ">
      <Header />
      <div className="container pt-52">
        <div className="row bg-white mt-3 w-75 m-auto">
          <div className="col-lg-12 p-4 ">
            <h3> Welcome to Community Forum - Where Tech Enthusiasts Unite</h3>
            <p>
              At Community Forum, we've created a vibrant and inclusive
              community where individuals from diverse backgrounds come together
              to discuss and explore topics ranging from frontend and backend
              development to machine learning, artificial intelligence, and
              mobile application development. Our forum is designed to be a
              collaborative space where questions are asked, ideas are
              exchanged, and solutions are discovered
            </p>
            <h4>What You Can Expect :</h4>
            <ul className="m-0 p-0">
              <li>
                <p>
                  {" "}
                  <b>Ask Questions :</b> Whether you're a beginner seeking
                  guidance or an experienced developer facing a roadblock, feel
                  free to post your questions. Our community is here to help you
                  find the answers you need.
                </p>
              </li>
              <li>
                <p>
                  <b>Share Knowledge:</b> Share your expertise by answering
                  questions and contributing to discussions. Your experiences
                  and knowledge can make a significant impact on someone else's
                  learning journey.
                </p>
              </li>
              <li>
                <p>
                  <b>Networking Opportunities: </b> Connect with like-minded
                  individuals, build professional relationships, and expand your
                  network. Community Forum is not just a place to learn; it's a
                  community where connections are forged
                </p>
              </li>
            </ul>
            <div className="flex items-center justify-end  space-x-2  border-gray-200 rounded-b dark:border-gray-600">
              <Link to="/login">
                {" "}
                <button
                  data-modal-hide="default-modal"
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 purple-btn"
                >
                  Join Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
