import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Style/style.css";
import "../../Style//Custom/custom.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import Header from "../../Layout/Header";
import Post from "../../Layout/Post";
import { useSelector } from "react-redux";
import { getRequest } from "../../Helper/httpProtocol";
import SidebarLeft from "../../Components/Sidebar/SidebarLeft";
import SidebarRight from "../../Components/Sidebar/SidebarRight";
import ContentLoader from "react-content-loader";

export default function Index() {
  const token = useSelector((state) => state.token);
  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
      <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
      <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
    </ContentLoader>
  );

  // api

  const [forum, setForum] = useState(); // data
  const getForum = async () => {
    getRequest("forum/trending")
      .then((res) => {
        setForum(res.data.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getForum();
  }, []);

  return (
    <>
      <div>
        <Header />

        <div className="container ">
          <div className="row pt-52">
            <div className=" col-12 col-lg-3 ">
              <SidebarLeft />
            </div>
            <div className=" col-12 col-lg-6 px-lg-0 edit-profile">
              <div className="pt-3">
                {forum?.length === undefined ? (
                  <div>
                    {" "}
                    <MyLoader className="mt-3" />
                    <MyLoader className="mt-3" />
                    <MyLoader className="mt-3" />
                  </div>
                ) : forum?.length > 0 ? (
                  <Post data={forum ? [] : forum} />
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <h1>No Trending</h1>
                  </div>
                )}
              </div>
            </div>
            <div className=" col-12 col-lg-3 mb-lg-0 mb-5 pb-lg-0 pb-5 ">
              <SidebarRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
