import React, { useEffect, useState } from "react";
import DefaultImage from "../../Assets/images/default.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
export default function LoginCard() {
  return (
    <>
      <div className="questions-card p-3  mb-1">
        <div className=" w-100">
          <div className="d-flex w-100">
            <div className="ms-3 mt-1 w-100 ">
              <h4>Login To Continue</h4>
            </div>
          </div>
          <div className="flex items-center justify-end pt-6 space-x-2  border-gray-200 rounded-b dark:border-gray-600">
            <Link to="/CreateAccount">
              <button
                data-modal-hide="default-modal"
                type="button"
                className=" bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 outline-btn"
              >
                Create Account
              </button>
            </Link>
            &nbsp; or
            <Link to="/login">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 purple-btn"
              >
                Login
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
