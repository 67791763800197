import React from "react";
import FollowerCard from "../../Components/Card/follower-card";
import ProfileCard from "../../Components/Card/profile-card";
import SidebarProfile from "../../Components/Sidebar/SidebarProfile";
import Header from "../../Layout/Header";
export default function MyFollower() {
  return (
    <div className="container-fluid">
      <div className="">
        <Header />
        <div className="container">
          <div className="d-flex">
            <div className="d-none d-md-block" style={{ width: " 300px" }}>
              <SidebarProfile />
            </div>
            <div className=" w-100 pt-52">
              <div className="bg-white p-3">
                <ProfileCard />
              </div>
              <h4 className="heading-border radius-5">
                Your Follower <span className="light-color"> (28)</span>{" "}
              </h4>{" "}
              <div className="d-flex d-flex justify-content-between  flex-wrap bg-white py-3 ">
                <div className="col-12 col-lg-6">
                  {" "}
                  <FollowerCard />
                </div>
                <div className="col-12 col-lg-6">
                  {" "}
                  <FollowerCard />
                </div>
                <div className="col-12 col-lg-6">
                  {" "}
                  <FollowerCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
