import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { useSelector, useDispatch } from "react-redux";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import SidebarProfile from "../../Components/Sidebar/SidebarProfile";
import SidebarRight from "../../Components/Sidebar/SidebarRight";
import { getRequest } from "../../Helper/httpProtocol";
import Header from "../../Layout/Header";
import Post from "../../Layout/Post";
import "../../Style//Custom/custom.css";
import "../../Style/style.css";

export default function Index() {
  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
      <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
      <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
    </ContentLoader>
  );
  const dispatch = useDispatch();
  const saveQuestion = useSelector((state) => state.saveQuestion);
  const saveQuestionTotal = useSelector((state) => state.saveQuestionTotal);

  const getSavedQuestion = async () => {
    const token = await localStorage.getItem("token");
    getRequest("forum/savequestion?take=5&skip=0", { token })
      .then(async (res) => {
        await dispatch({
          type: "saveQuestion",
          saveQuestion: res.data.data,
        });
        await dispatch({
          type: "saveQuestionTotal",
          saveQuestionTotal: res.data.total,
        });
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getSavedQuestion();
  }, []);
  return (
    <>
      <div>
        <Header />

        <div className="container ">
          <div className="row pt-52">
            <div className=" col-12 col-lg-3 ">
              <SidebarProfile />
            </div>
            <div className=" col-12 col-lg-6 px-lg-0 edit-profile ">
              <div className="pt-3">
                <h4 className="heading-border radius-5">
                  Your Saved Question{" "}
                  <span className="light-color">
                    {" "}
                    {`(${saveQuestionTotal})`}
                  </span>{" "}
                </h4>{" "}
                {saveQuestion === undefined ? (
                  <div>
                    {" "}
                    <MyLoader className="mt-3" />
                    <MyLoader className="mt-3" />
                    <MyLoader className="mt-3" />
                  </div>
                ) : (
                  <Post
                    data={saveQuestion}
                    type={"saved"}
                    totalForums={saveQuestionTotal}
                  />
                )}
              </div>
            </div>
            <div className=" col-12 col-lg-3 mb-lg-0 mb-5 pb-lg-0 pb-5 ">
              <SidebarRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
