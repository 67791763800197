import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "flowbite-react";
import React, { useState } from "react";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import CountryList from "../../Data/country";
import { postRequest } from "../../Helper/httpProtocol";
import Header from "../../Layout/Header";
import "../../Style//Custom/custom.css";
import "../../Style/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SidebarProfile from "../../Components/Sidebar/SidebarProfile";
import axios from "axios";

export default function EditProfile() {
  const cloud_name = "de0q6n3md";
  const preset_name = "user-image";
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [updatedData, setUpdatedData] = useState({});
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [country, setCountry] = useState(user?.country || '');
  const [city, setCity] = useState(user?.city || '');
  const [facebook, setFacebook] = useState(user?.facebook || '');
  const [linkedin, setLinkedin] = useState(user?.linkedin || '');
  const [website, setWebsite] = useState(user?.website || '');
  const [youtube, setYouTube] = useState(user?.youtube || '');
  const [twitter, setTwitter] = useState(user?.twitter || '');
  const [instagram, setInstagram] = useState(user?.instagram || '');
  const [github, setGithub] = useState(user?.github || '');
  const [password, setPassword] = useState('');
  const [interest, setInterest] = useState(user?.interest || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
  const [newProfilePicture, setNewProfilePicture] = useState(user?.photoURL || '');
  const [no, setNo] = useState(0);
  const [allow, setAllow] = useState(false);
  const handleCountry = (e) => {
    const val = e.target.value;
    setCountry(val);
    setAllow(true);
    const selectedIndex = CountryList.findIndex(
      (country) => country.countryName === val
    );
    setNo(selectedIndex);
  };
  const navigate = useNavigate();


  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPhoneNumber = (phoneNumber) => /^[+]?[0-9]{10,15}$/.test(phoneNumber);
  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };
  const dispatch = useDispatch();
  const updateProfile = async () => {
    if (!firstName || firstName.trim().length < 2) {
      toast.error("Please enter a valid first name with at least 2 characters");
      return;
    }
    if (!lastName || lastName.trim().length < 2) {
      toast.error("Please enter a valid last name with at least 2 characters");
      return;
    }
    if (!email || !isValidEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    if (!country) {
      toast.error("Please select your country");
      return;
    }
    if (facebook && !isValidURL(facebook)) {
      toast.error("Please enter a valid Facebook URL");
      return;
    }
    if (github && !isValidURL(github)) {
      toast.error("Please enter a valid GitHub URL");
      return;
    }
    if (linkedin && !isValidURL(linkedin)) {
      toast.error("Please enter a valid linkedin URL");
      return;
    }
    if (instagram && !isValidURL(instagram)) {
      toast.error("Please enter a valid Instagram URL");
      return;
    }
    if (twitter && !isValidURL(twitter)) {
      toast.error("Please enter a valid Twitter URL");
      return;
    }
    if (youtube && !isValidURL(youtube)) {
      toast.error("Please enter a valid YouTube URL");
      return;
    }
    if (website && !isValidURL(website)) {
      toast.error("Please enter a valid website URL");
      return;
    }
    await postRequest(
      "auth/update",
      {
        firstName,
        lastName,
        email,
        password,
        interest,
        phoneNumber,
        facebook,
        github,
        instagram,
        twitter,
        youtube,
        website,
        city,
        country,
        linkedin,
        photoURL: newProfilePicture
      },
      { token }
    ).then((res) => {
      dispatch({ type: "user", user: res.data.data });
      toast(res.data.message);
      navigate("/profile");
    });
  };
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_name);
    axios
      .post(`https://api.cloudinary.com/v1_1/${cloud_name}/upload`, formData)
      .then((res) => setNewProfilePicture(res.data.secure_url))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Header />
      {/* <Pagination /> */}
      <div className="container ">
        <div className="d-flex pt-52">
          <div className="d-none d-md-block" style={{ width: " 300px" }}>
            <SidebarProfile />
          </div>
          <div className="w-100 edit-profiles ">
            <div className="bg-white p-3">
              <div className="row ">
                <div className="col-6 ">
                  {" "}
                  <h3 className="my-3"> Edit Profile</h3>
                </div>
              </div>
              <div className="profile-card p-4 ">
                <div>
                  {/* {user && user.photoURL ? (
                    <img src={user.photoURL} alt="" />
                  ) : (
                    <img src={defaultImage} alt="" />
                  )} */}
                  <img src={newProfilePicture} alt="" />
                </div>
                <div className="ms-3 pt-3">
                  <div>
                    <div class="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        class="flex items-center justify-center cursor-pointer"
                      >
                        <div class="flex flex-col items-center justify-center upload-pic  px-3">
                          <p class="text-xs dark:text-gray-400 py-2 m-0">
                            Upload profile picture{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="16"
                              viewBox="0 0 20 16"
                              fill="none"
                            >
                              <rect
                                x="0.584193"
                                y="3.5107"
                                width="15.7509"
                                height="11.2923"
                                rx="1"
                                transform="rotate(-10.7077 0.584193 3.5107)"
                                stroke="#17171F"
                              />
                              <rect
                                x="3.86889"
                                y="1.44295"
                                width="15.7509"
                                height="10.5596"
                                rx="1"
                                transform="rotate(8.93753 3.86889 1.44295)"
                                fill="white"
                                stroke="#17171F"
                              />
                              <circle
                                cx="7.80562"
                                cy="5.22786"
                                r="1.33687"
                                fill="#17171F"
                              />
                              <path
                                d="M9.41037 8.97101C6.81022 8.33987 5.78294 8.66653 4.59766 10.3079L14.7578 11.6447L15.8273 11.3774L15.0252 7.63415L13.9557 8.97101L12.0841 6.02991L9.41037 8.97101Z"
                                fill="#17171F"
                                stroke="#17171F"
                              />
                            </svg>
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          class="hidden"
                          onChange={handleImageUpload}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" con-card p-3 mb-3 mt-3">
                <div className="row">
                  <div className=" col-12 ">
                    <h4>Personal Information</h4>
                  </div>
                  <div className="col-lg-6 col-12 py-lg-1">
                    <div className="mb-3">
                      <label>First Name</label>
                      <input
                        id="firstName"
                        name="firstName"
                        className="form-control"
                        placeholder="firstName"
                        required={true}
                        value={firstName ? firstName : user?.firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <lable htmlFor="email">Email</lable>
                      <input
                        id="email"
                        name="email"
                        className="form-control"
                        value={email ? email : user?.email}
                        placeholder="email"
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="countries">Country</label>
                      <select
                        id="countries"
                        required
                        className="form-control"
                        onChange={(e) => handleCountry(e)}
                        value={country}
                      >
                        {CountryList.map((item, index) => (
                          <option key={index} value={item.countryName}>
                            {item.countryName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 py-lg-1">
                    <div className="mb-3">
                      <lable htmlFor="lastName">LastName</lable>
                      <input
                        id="lastName"
                        name="lastName"
                        value={lastName ? lastName : user?.lastName}
                        className="form-control"
                        placeholder="lastName"
                        required={true}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <lable htmlFor="phoneNumber">Phone</lable>
                      <input
                        name="phoneNumber"
                        value={phoneNumber ? phoneNumber : user?.phoneNumber}
                        id="phoneNumber"
                        className="form-control"
                        placeholder="Phone Number"
                        required={true}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="city">City</label>
                      <select
                        id="city"
                        required
                        className="form-control"
                        onChange={(e) => setCity(e.target.value)}
                      >
                        {allow
                          ? CountryList[no].regions.map((item, index) => (
                            <option
                              key={index}
                              name="country"
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          ))
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" con-card p-3 mb-3 mt-3">
                <div className="row">
                  <div className=" col-12 ">
                    <h4>Social Links</h4>
                  </div>
                  <div className="col-lg-6 col-12 py-lg-1">
                    <div className="mb-3">
                      <lable htmlFor="Facebook">Facebook</lable>
                      <input
                        id="Facebook"
                        name="Facebook"
                        value={facebook ? facebook : user?.facebook}
                        className="form-control"
                        placeholder="https://www.facebook.com/sarah.john/"
                        required={true}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <lable htmlFor="Youtube">Youtube</lable>
                      <input
                        id="Youtube"
                        name="Youtube"
                        value={youtube ? youtube : user?.youtube}
                        className="form-control"
                        placeholder="https://www.Youtube.com/sarah.john/"
                        required={true}
                        onChange={(e) => setYouTube(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <lable htmlFor="Instagram">Instagram</lable>
                      <input
                        id="Instagram"
                        name="Instagram"
                        value={instagram ? instagram : user?.instagram}
                        className="form-control"
                        placeholder="https://www.Instagram.com/sarah.john/"
                        required={true}
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 py-lg-1">
                    <div className="mb-3">
                      <lable htmlFor="Facebook">Twitter</lable>
                      <input
                        id="Twitter"
                        name="Twitter"
                        value={twitter ? twitter : user?.twitter}
                        className="form-control"
                        placeholder="https://www.Twitter.com/sarah.john/"
                        required={true}
                        onChange={(e) => setTwitter(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <lable htmlFor="LinkedIn">LinkedIn</lable>
                      <input
                        id="LinkedIn"
                        name="LinkedIn"
                        value={linkedin ? linkedin : user?.linkedin}
                        className="form-control"
                        placeholder="https://www.LinkedIn.com/sarah.john/"
                        required={true}
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <lable htmlFor="Github">Github</lable>
                      <input
                        id="Github"
                        name="Github"
                        value={github ? github : user?.github}
                        className="form-control"
                        placeholder="https://www.Github.com/sarah.john/"
                        required={true}
                        onChange={(e) => setGithub(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" con-card p-3 mb-3 mt-3">
                <div className="row">
                  <div className=" col-12 ">
                    <h4>Website</h4>
                  </div>
                  <div className="col-lg-6 col-12 py-lg-1">
                    <div className="mb-3">
                      <lable htmlFor="lastName">Website</lable>
                      <input
                        id="Website"
                        name="Website"
                        value={website ? website : user?.website}
                        className="form-control"
                        placeholder="www.sarahjohn.com"
                        required={true}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="update-div mb-lg-0 mb-5 pb-lg-0 pb-2">
                <Button onClick={updateProfile} className="btn-update">
                  Update Profile
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
