import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import DefaultImage from "../../Assets/images/default.png";
import LoginCard from "../../Components/Card/login-card";
import SidebarLeft from "../../Components/Sidebar/SidebarLeft";
import SidebarRight from "../../Components/Sidebar/SidebarRight";
import { getRequest, postRequest } from "../../Helper/httpProtocol";
import Header from "../../Layout/Header";
import Post from "../../Layout/Post";
import "../../Style//Custom/custom.css";
import "../../Style/style.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateTopic from "../../Components/Modal/create-topic";

export default function Index() {
  const token = useSelector((state) => state.token);
  const forumsDraft = useSelector((state) => state.forumsDraft);
  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
      <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
      <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
    </ContentLoader>
  );
  const user = useSelector((state) => state.user);
  const forums = useSelector((state) => state.forums);
  const totalForums = useSelector((state) => state.totalForums);
  const dispatch = useDispatch();

  // api
  const getForum = async () => {
    getRequest("forum/getall?take=5&skip=0", { token: localStorage.getItem("token") })
      .then(async (res) => {
        console.log("Here is the Forum Data: ", res.data.data);
        dispatch({ type: "forums", forums: res.data.data });
        dispatch({ type: "totalForums", totalForums: res.data.total });
      }).catch((error) => console.log(error));
  };
  useEffect(() => {
    getForum();
  }, []);
  const navigate = useNavigate();
  const getDraftForum = async () => {
    getRequest("forums/getalldraft?take=5&skip=0", { token: localStorage.getItem("token") })
      .then(async (res) => {
        dispatch({ type: "forumsDraft", forumsDraft: res.data.data });
        dispatch({
          type: "totalDraftForums",
          totalDraftForums: res.data.total,
        });
      })
      .catch((error) => console.log(error));
  };
  const id = forumsDraft[0]?._id
  const draftToPublish = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await postRequest(
        "forums/drafttopublish",
        { questionId: id },
        { token }
      ).then((res) => {
        getDraftForum();
        toast(res.data.message);
      });
    } else navigate("/login");
  };

  return (
    <>
      <div>
        <Header />
        <div className="container ">
          <div className="row pt-52">
            <div className=" col-12 col-lg-3 ">
              <SidebarLeft />
            </div>
            <div className=" col-12 col-lg-6 px-lg-0 edit-profile ">
              <div className="pt-3">
                {token ? <div className="questions-card p-3  mb-1">
                  <div className=" w-100">
                    <div style={{ width: "100%", display: 'flex', alignItems: 'center', gap: "0px" }}>
                      <div>
                        {user && user?.photoURL ? <img style={{ marginTop: "4px" }} src={user?.photoURL} alt="" /> : <img src={DefaultImage} alt="" />}

                      </div>
                      <div className="ms-3 mt-1 w-100 ">
                        <CreateTopic
                          description={forumsDraft[0]?.description}
                          title={forumsDraft[0]?.title}
                          questionId={forumsDraft[0]?.id}
                          category={forumsDraft[0]?.category}
                          subCategory={forumsDraft[0]?.subCategory}
                        >
                          <input
                            type="text"
                            className="comment-area"
                            style={{ height: "53px" }}
                            placeholder="Write your Question"
                            value={forumsDraft[0]?.description}
                          // dangerouslySetInnerHTML={{
                          //   __html: forumsDraft[0]?.description,
                          // }}
                          />
                        </CreateTopic>
                      </div>
                    </div>
                    <div className="d-flex justify-end">
                      <button
                        type="submit"
                        className="btn btn-primary purple-btn mt-2"
                        onClick={draftToPublish}
                      >
                        Post
                      </button>
                    </div>
                  </div>
                </div> : <LoginCard />}

                {forums?.length < 1 ? <div>
                  <MyLoader className="mt-3" />
                  <MyLoader className="mt-3" />
                  <MyLoader className="mt-3" />
                </div> : <Post forums={forums} totalForums={totalForums} />}
              </div>
            </div>
            <div className=" col-12 col-lg-3 mb-lg-0 mb-5 pb-lg-0 pb-5 ">
              <SidebarRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
