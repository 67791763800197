import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { mainCategories } from "../../Config/Consent";
import { toast } from "react-toastify";
import { Avatar, Button, Navbar, Dropdown } from "flowbite-react";
import defaultImage from "../../Assets/images/default.png";
import CreateTopic from "../../Components/Modal/create-topic";

export default function Index() {
  // const token = useSelector((state) => state.token);
  const token = localStorage.getItem("token")
  // const user = useSelector((state) => state.user);
  const user = JSON.parse(localStorage.getItem("userData"))
  console.log(user, "userheader")
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const search = async (e) => {
    e.preventDefault();
    if (searchValue.length < 1) {
      toast("Enter Search Value");
    } else {
      await navigate(`/search/${searchValue.replace(" ", "-")?.toLowerCase()}`);
    }
  };

  console.log(token, "toekn")



  return (
    <>
      <Navbar className="navbar-nav-purple">
        <Navbar.Brand href="/" className="text-decoration-none text-primary">
          <span className="fs-2 fw-bold color-white text-nowrap">
            JS Pedia
          </span>
        </Navbar.Brand>

        <div className="flex md:order-2">
          {token ? (
            <>
              <CreateTopic>
                <Icon icon="ic:sharp-plus" className="text-white text-35" />
              </CreateTopic>
              <Dropdown
                arrowIcon={false}
                inline
                label={
                  <Icon
                    icon="clarity:notification-solid"
                    className="text-white text-35 me-2"
                  />
                }
              >
                <Dropdown.Header>
                  <span className="block text-sm">{user?.firstName}</span>
                </Dropdown.Header>
              </Dropdown>
              <Dropdown
                arrowIcon={false}
                inline
                className="profile-drop"
                label={
                  user && user.photoURL ? (
                    <img
                      className="circular-img"
                      src={user.photoURL}
                      alt=""
                      width={"100px !important"}
                      height={"80px"}
                    />
                  ) : (
                    <img className="circular-img" src={defaultImage} alt="" />
                  )
                }
              >
                <Dropdown.Item>
                  {user && user.photoURL ? (
                    <img
                      className="circular-img me-2"
                      src={user.photoURL}
                      alt=""
                      width={"100px !important"}
                      height={"80px"}
                    />
                  ) : (
                    <img
                      className="circular-img me-2"
                      src={defaultImage}
                      alt=""
                    />
                  )}
                  {user && user.firstName ? (
                    <div className="drop-name">{`${user.firstName} ${user.lastName}`}</div>
                  ) : (
                    <></>
                  )}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Link to="/profile" className="profile-link">
                    <Icon icon="ep:setting" className="me-4" /> Setting
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon icon="octicon:question-24" className="me-4" /> Help
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={async () => {
                    await localStorage.removeItem("token");
                    navigate("/login");
                  }}
                >
                  <Icon icon="quill:off" className="me-4" />
                  Sign out
                </Dropdown.Item>
              </Dropdown>
            </>
          ) : (
            <Link to="/login">
              <button
                type="submit"
                className="btn btn-primary purple-btn color-purple"
                style={{ backgroundColor: "#f1ecfd" }}
              >
                Login
              </button>
            </Link>
          )}
          <Navbar.Toggle className="lg:hidden toggle-btn" />
        </div>

        <Navbar.Collapse className="nav-ul">
          <NavLink className="nav-a" to="/">
            Home
          </NavLink>

          <NavLink className="nav-a" to="/about">
            About
          </NavLink>

          <NavLink className="nav-a me-lg-5" to="/contact">
            Contact
          </NavLink>

          <form>
            <div class="flex w-res-150">
              <label
                for="search-dropdown"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Your Email
              </label>

              <Dropdown
                label="Category"
                dismissOnClick={false}
                class="flex-shrink-0 z-10 inline-flex items-center py-0.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 "
              >
                <div className="nav-dropdown">
                  <Dropdown.Item>
                    <Link to="/all-categories">All Categories</Link>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {mainCategories.map((e) => (
                    <>
                      <Dropdown.Item>
                        <Link
                          to={`/category/${e.name
                            ?.replace(" ", "-")
                            ?.toLowerCase()}`}
                        >
                          {e.name}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Divider className="m-0" />
                    </>
                  ))}
                </div>
              </Dropdown>
              <div class="relative w-full">
                <input
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="search"
                  id="search-dropdown"
                  class="block p-2.5 w-full z-20 text-sm text-gray-900  rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                  placeholder="Search Here"
                  required
                />
                <button
                  onClick={search}
                  type="submit"
                  class="absolute top-0 right-5 p-2.5 text-sm font-medium h-full color-purple"
                >
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  <span class="sr-only">Search</span>
                </button>
              </div>
            </div>
          </form>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
