import React, { useEffect, useState } from "react";
import DefaultImage from "../../Assets/images/default.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
export default function FollowerCard() {
  // const {
  //   title,
  //   detail,
  // path
  // }=props
  return (
    <>
      <div className="following-card p-3 mx-3">
        <div className="d-flex">
          {" "}
          <div>
            <img src={DefaultImage}></img>
          </div>
          <div className="mx-3">
            <span>Stefan Salvatore</span>
            <div className="following-card-info">
              <b>7.2k</b>
              <span> Answers</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
