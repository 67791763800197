import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import Answer from "../../Components/Box/Forum/Answer";
import Question from "../../Components/Box/Forum/Question";
import ReplyTopic from "../../Components/Form/Reply";
import SidebarLeft from "../../Components/Sidebar/SidebarLeft";
import { getRequest } from "../../Helper/httpProtocol";
import Header from "../../Layout/Header";
import { useDispatch, useSelector } from "react-redux";
export default function Index() {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState();
  const [editAnswer, setEditAnswer] = useState("");
  const allAnswer = useSelector((state) => state.answers);

  const dispatch = useDispatch();
  const getDetail = async () => {
    await getRequest("forum/get", {
      forumId: window.location.pathname.split("/")[2],
    }).then((res) => {
      setData(res.data.data);
      console.log(res.data.data, "answer")
      setLoad(false);
      getRequest("forum/getanswers", { questionId: res.data.data?._id }).then(
        (res) => dispatch({ type: "answers", answers: res.data.data })
      );
    });
  };
  useEffect(() => {
    getDetail();
  }, []);

  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
      <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
      <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
    </ContentLoader>
  );


  return (
    <>
      <div>
        <Header />

        <div className="container ">
          <div className="row pt-52">
            <div className=" col-12 col-lg-3 ">
              <SidebarLeft />
            </div>
            <div className=" col-12 my-3 col-lg-9 edit-profile ">
              <div>
                {load ? (
                  <>
                    {" "}
                    <div className="questions-card p-3 border-bottom-left-radius-0 border-bottom-right-radius-0">
                      <MyLoader className="mt-3" />
                    </div>
                  </>
                ) : (
                  <Question
                    id={data?._id}
                    userId={data?.userId}
                    question={data?.title}
                    description={data?.description}
                    postedTime={data?.createdAt}
                  />
                )}
              </div>
              <div className="p-3 bg-white mb-lg-0 mb-5 pb-lg-0 pb-2 border-top-left-radius-0 border-top-right-radius-0 ">
                <ReplyTopic
                  questionId={data?._id}
                  getDetail={getDetail}
                  EditAnswer={editAnswer}
                />
                {allAnswer === undefined ? (
                  <div>
                    {" "}
                    <MyLoader className="mt-3" />
                    <MyLoader />
                    <MyLoader />
                  </div>
                ) : (
                  allAnswer.map((e, index) => {
                    return (
                      <Answer
                        id={e._id}
                        userId={e?.userId}
                        answer={e?.answer}
                        postedTime={e?.createdAt}
                        EditHandle={() => setEditAnswer(e?.answer)}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
