import React, { useState } from "react";
import Header from "../../Layout/Header";
import Post from "../../Layout/Post";
import SidebarRight from "../../Components/Sidebar/SidebarRight";
// import { getRequest } from "../../Helper/httpProtocol";
export default function Index() {
  const [forum, setForum] = useState([]);
  // const getForum = async () => {
  //   getRequest("forum/getall").then((res) => {
  //     setForum(
  //       res.data.data.filter((e) =>
  //         e.title.toLowerCase().includes(window.location.pathname.split("/")[2])
  //       )
  //     );
  //   });
  //   console.log("yes").catch((error) => console.log(error));
  // };
  // useEffect(() => {
  //   // getForum();
  // }, [forum]);

  return (
    <div className="container-fluid ">
      <Header />
      <div className="container">
        <div className="row pt-52">
          {forum.length <= 0 ? (
            <div className=" d-flex align-items-center justify-content-center">
              {" "}
              <h3 className="mt-5 m-auto pt-5 "> No Search Found </h3>{" "}
            </div>
          ) : (
            <Post data={forum} />
          )}
          <SidebarRight />
        </div>
      </div>
    </div>
  );
}
