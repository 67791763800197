
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { NavLink, Link } from "react-router-dom";
export default function SidebarProfile() {
  return (
    <div className="sidebarLeft">
      <ul class="list-group">
        <NavLink to="/profile" className="mt-3">
          {" "}
          <li class="list-group-item  ">
            <Icon icon="solar:user-linear" />
            <span> Profile</span>
          </li>
        </NavLink>
        <NavLink to="/myquestion" className="mt-3">
          {" "}
          <li class="list-group-item ">
            <Icon icon="healthicons:q-outline" />
            <span> Question </span>
          </li>
        </NavLink>
        <NavLink to="/myanswer" className="mt-3">
          {" "}
          <li class="list-group-item " >
            <Icon icon="healthicons:a-outline" />
            <span> Answer</span>
          </li>
        </NavLink>
        <NavLink to="/saved" className="mt-3">
          {" "}
          <li class="list-group-item ">
            <Icon icon="akar-icons:ribbon" />
            <span> Saved</span>
          </li>
        </NavLink>
        {/* <NavLink to="/myfollower"> */}
        <Link to="" className="mt-3">
          {" "}
          <li class="list-group-item ">
            <Icon icon="material-symbols-light:group-outline" />
            <span>
              {" "}
              Followers <span class=" coming-soon-tag">coming soon</span>
            </span>
          </li>
        </Link>
        {/* </NavLink> */}
        {/* <NavLink to="/myfollowing"> */}
        <Link to="" className="mt-3">
          {" "}
          <li class="list-group-item ">
            <Icon icon="solar:user-heart-linear" />
            <span>
              {" "}
              Following <span class=" coming-soon-tag">coming soon</span>
            </span>
          </li>
        </Link>
        {/* </NavLink> */}
      </ul>
    </div>
  );
}
