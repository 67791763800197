import React from "react";

import { Link } from "react-router-dom";
export default function CategoriesBlock(props) {
  const { title, detail, path } = props;
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4   my-1">
        <div className="card py-4 px-2 ">
          <h4 className="sub-category-heading">{title}</h4>

          <p>{detail}</p>
          <Link to={path}>
            <div className="btn-primary btn"> View Questions</div>
          </Link>
        </div>
      </div>
    </>
  );
}
