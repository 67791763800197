import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import Post from "../../Components/Post";
import { getRequest } from "../../Helper/httpProtocol";

export default function Index({ forums, totalForums }) {
  const dispatch = useDispatch();
  const itemsPerPage = 5;

  const handlePageClick = (event) => {
    getRequest(`forum/getall?take=${itemsPerPage}&skip=${event.selected * itemsPerPage}`, { token: localStorage.getItem('token') })
      .then(async (res) => {
        dispatch({ type: "forums", forums: res.data.data });
        dispatch({ type: "totalForums", totalForums: res.data.total });
      }).catch((error) => console.log(error));
  };
  return <div className=" mb-4 ">
    {forums?.map(forum => <Post forum={forum} />)}
    {totalForums >= 5 ?
      <ReactPaginate
        nextLabel=" >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalForums / itemsPerPage}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      /> : null}
  </div>
}
