import axios from "axios";

const baseUrl = `http://localhost:8000`;
// const baseUrl = `https://js-forum-backend.vercel.app`;
const apiUrl = `${baseUrl}/api`;

const getRequest = (endPoint, headPayload) => {
  const result = axios.get(`${apiUrl}/${endPoint}`, {
    headers: headPayload,
  });
  if (result) {
    return result;
  }
};

const postRequest = async (endPoint, payload, headPayload) => {
  const result = await axios.post(`${apiUrl}/${endPoint}`, payload, {
    headers: headPayload,
  });
  if (result) return result;
};

export { getRequest, postRequest, apiUrl };
