import React, { useEffect } from "react";
import AppRouter from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import store from "./Store";
import { Provider } from "react-redux";
// import TawkTo from "tawkto-react";
export default function App() {
  // const propertyIdTawkTo = "656b4419bfb79148e599725d";
  // const tawkId = "1hglg415c";
  // useEffect(() => {
  //   var tawk = new TawkTo(propertyIdTawkTo, tawkId);

  //   tawk.onStatusChange((status) => {});
  // }, []);
  return (
    <Provider store={store}>
      <AppRouter />
      <ToastContainer />
      <TawkMessengerReact
        propertyId="6568acc3ff45ca7d47854f9f"
        widgetId="default"
      />
    </Provider>
  );
}
