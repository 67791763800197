import React, { useEffect, useState } from "react";
import DefaultImage from "../../Assets/images/default.png";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
export default function AnswerCard() {
  // const {
  //   title,
  //   detail,
  // path
  // }=props
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [upIcon, setUpIcon] = useState(false);
  const [downIcon, setDownIcon] = useState(false);
  const [noOfLike, setNoOfLike] = useState(3);
  const [noOfUnLike, setNoOfUnLike] = useState(15);
  const handleIconLike = () => {
    if (token) {
      setUpIcon(!upIcon);
      setNoOfLike(noOfLike + (upIcon ? -1 : 1));
    } else {
      navigate("/login");
    }
  };
  const handleIconUnLike = () => {
    setDownIcon(!downIcon);
    setNoOfUnLike(noOfUnLike + (upIcon ? -1 : 1));
  };

  return (
    <>
      <div className="questions-card p-3">
        <div className="d-flex">
          {" "}
          <img src={DefaultImage}></img>
          <div className="mx-3">
            <span>What is the purpose of Javascript?</span>
            <div className="d-flex mt-3">
              {" "}
              <img src={DefaultImage}></img>
              <div className="mx-3 ">
                <p>
                  The purpose of a JavaScript closure is to allow a function to
                  maintain access to variables from its venting it from being
                  accessed or modified from outside the function. This is
                  crucial for maintaining data integrity and avoiding unintended
                  changes. Closures enable the creation of private variables.
                  Variable... Read more
                </p>
              </div>
            </div>
            <div className="icon-div">
              <div className="d-flex" onClick={handleIconLike}>
                {" "}
                {upIcon ? (
                  <Icon icon="ph:arrow-fat-up-fill" />
                ) : (
                  <Icon icon="ph:arrow-fat-up-light" />
                )}{" "}
                <span>{noOfLike}</span>
              </div>{" "}
              <div className="d-flex" onClick={handleIconUnLike}>
                {" "}
                {downIcon ? (
                  <Icon icon="ph:arrow-fat-down-fill" className="ms-3" />
                ) : (
                  <Icon icon="ph:arrow-fat-down-light" className="ms-3" />
                )}{" "}
                <span>{noOfUnLike}</span>
              </div>{" "}
              <Icon icon="heroicons-outline:chat" className="ms-3" />{" "}
              <span>1.1k</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
