import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Style/style.css'
import '../../Style//Custom/custom.css'
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import Header from '../../Layout/Header'
import Post from '../../Layout/Post'
import SideBar from '../../Layout/SideBar'

import Pagination from '../../Layout/Pagination'
import NewTopicForm from '../../Components/Form/NewTopic'
import RegistrationForm from '../../Components/Form/Registration'
export default function Index() {
    return (
      <div className="container-fluid login-screen">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 ">
            {" "}
            <RegistrationForm referenceId={"login"} />
          </div>

          {/* <SideBar /> */}
        </div>
      </div>
    );
}
