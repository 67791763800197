import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Dropdown } from "flowbite-react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../../Assets/images/default.png";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../../Helper/httpProtocol";
import Report from "../../../Modal/report";

export default function Index({
  id,
  question,
  description,
  postedTime,
  userId
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveQuestionData = useSelector((state) => state.saveQuestion);
  const [upIcon, setUpIcon] = useState(false);
  const [downIcon, setDownIcon] = useState(false);
  const [votesCount, setVotesCount] = useState({ up: 0, down: 0 });
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [upVote, setUpVote] = useState(0);
  const [downVote, setDownVote] = useState(0);
  const [questionLength, setQuestionLength] = useState(0);

  const getSavedQuestion = async () => {
    const token = localStorage.getItem("token");
    getRequest("forum/savequestion?take=5&skip=0", { token })
      .then(async (res) => {
        dispatch({
          type: "saveQuestion",
          saveQuestion: res.data.data,
        });
        dispatch({
          type: "saveQuestionTotal",
          saveQuestionTotal: res.data.total,
        });
      })
      .catch((error) => console.log(error));
  };
  const saveQuestion = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await postRequest(
        "forum/savequestion",
        { questionId: id },
        { token }
      ).then((res) => {
        getSavedQuestion();
        toast(res.data.message);
      });
    } else {
      navigate("/login");
    }
  };
  const getAllAnswer = async () => {
    getRequest("forum/getanswers", { questionId: id, userId }).then((res) => {
      setQuestionLength(res.data.data.length);
    });
  };

  const createVote = async (vote = null) => {
    if ((vote === "up" && upIcon) || (vote === "down" && downIcon)) {
      setUpIcon(false)
      setDownIcon(false)
    } else if (vote === "up") setUpIcon(true)
    else if (vote === "down") setDownIcon(true)

    await postRequest(
      "forum/vote",
      { questionId: id, vote },
      { token }
    ).then((res) => {
      toast(res.data.message);
      getUpVote();
    });
  };

  const createUpVote = async () => {
    setUpIcon(!upIcon);
    await postRequest("forum/createupvote", { questionId: id }, { token })
      .then((res) => {
        getUpVote();
      })
      .catch(async (err) => {
        navigate("/login");
      });
  };
  const createDownVote = async () => {
    setDownIcon(!downIcon);
    await postRequest("forum/createdownvote", { questionId: id }, { token })
      .then((res) => {
        getDownVote();
      })
      .catch(async (err) => {
        toast(err.response.data.message);
        navigate("/login");
      });
  };

  const getUpVote = async () => {
    await getRequest("forum/getupvote", { questionId: id }).then((res) => {
      setUpVote(res.data.count);
      const isLike = res.data?.data?.some(item => item?.userId === user?._id);
      setUpIcon(isLike)
    });
  };

  const getDownVote = async () => {
    await getRequest("forum/getdownvote", { questionId: id }).then((res) => {
      setUpVote(res.data.count);
      const isDislike = res.data?.data?.some(item => item?.userId === user?._id);
      setDownIcon(isDislike)
    });
  };

  useEffect(() => {
    getUpVote();
    getDownVote();
    getAllAnswer();
  }, []);

  const handleCopy = () => {
    const textToCopy = `${window.location.origin}/topic/${id}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast("Copied");
      })
      .catch((error) => {
        toast("Not Copied");
      });
  };
  return (
    <>
      <div className="questions-card p-3 border-bottom-left-radius-0 border-bottom-right-radius-0">
        <div className="d-flex">
          {" "}
          <div style={{ width: "35px" }}>
            {userId && userId?.photoURL ? (
              <img src={userId?.photoURL} alt="" />
            ) : (
              <img src={defaultImage} alt="" />
            )}
          </div>
          <div className="mx-3 w-100">
            <div>
              <div>
                <span className="user_name">{`${userId?.firstName} ${userId?.lastName}`}</span>
                {userId?._id === user?._id && (
                  <span className="user_name_me">(me) </span>
                )}
              </div>

              <span className="ms-0"> {question}</span>
              <p className="description-para mb-0 d-inline-block">
                <span
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></span>
              </p>
            </div>
            <div className="icon-div">
              <div className="d-flex" onClick={() => createVote("up")}>
                {" "}
                {upIcon ? (
                  <Icon icon="ph:arrow-fat-up-fill" />
                ) : (
                  <Icon icon="ph:arrow-fat-up-light" />
                )}{" "}
                <span> {upVote}</span>
              </div>{" "}
              <div className="d-flex" onClick={() => createVote("down")}>
                {" "}
                {downIcon ? (
                  <Icon icon="ph:arrow-fat-down-fill" className="ms-3" />
                ) : (
                  <Icon icon="ph:arrow-fat-down-light" className="ms-3" />
                )}{" "}
                <span> {downVote}</span>
              </div>{" "}
              <Icon icon="heroicons-outline:chat" className="ms-3" />{" "}
              <span>{questionLength}</span>
            </div>
          </div>
        </div>
        <div className="action-div">
          <div>
            <Dropdown
              arrowIcon={false}
              inline
              className="profile-drop"
              label={<Icon icon="bi:three-dots" />}
            >
              {
                token && <Report questionId={id}>
                  {" "}
                  <Dropdown.Item>Report</Dropdown.Item>{" "}
                </Report>
              }

              <Dropdown.Divider />
              <Dropdown.Item onClick={handleCopy}>Copy Link</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item>Not Interested</Dropdown.Item>
            </Dropdown>
          </div>
          <div>
            {saveQuestionData?.find((item) => item?._id === id) ? (
              <Icon
                icon="ic:outline-bookmark"
                className="color-purple cursor-pointer"
                onClick={saveQuestion}
              />
            ) : (
              <Icon
                icon="mdi:bookmark-outline"
                className="color-purple cursor-pointer"
                onClick={saveQuestion}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
