"use client";
import { Modal } from "flowbite-react";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  backendCategories,
  frontEndCategories,
  machineLearningCategories,
  mainCategories,
  mobileApplicationDevelopmentCategories,
} from "../../Config/Consent";
import { getRequest, postRequest } from "../../Helper/httpProtocol";
export default function CreateTopic(props) {
  const [openModal, setOpenModal] = useState(false);
  const [description, setDescription] = useState(props?.description);
  const [title, setTitle] = useState(props?.title);
  const [data, setData] = useState({ subCategory: props?.subCategory });
  const [category, setCategory] = useState(props?.category);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    data[event.target.name] = event.target.value;
    setData(data);
  };
  const getForum = async () => {
    getRequest("forum/getall?take=5&skip=0")
      .then(async (res) => {
        await dispatch({ type: "forums", forums: res.data.data });
        await dispatch({ type: "totalForums", totalForums: res.data.total });
      })
      .catch((error) => console.log(error));
  };

  const getDraftForum = async () => {
    const token = await localStorage.getItem("token");
    getRequest("forum/getalldraft?take=5&skip=0", { token })
      .then(async (res) => {
        await dispatch({ type: "forumsDraft", forumsDraft: res.data.data });
        await dispatch({
          type: "totalDraftForums",
          totalDraftForums: res.data.total,
        });
      })
      .catch((error) => console.log(error));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await localStorage.getItem("token");
    postRequest(
      "forum/create",
      {
        ...data,
        category,
        title: title,
        description: description,
        questionId: props?.id,
      },
      { token }
    )
      .then(async (res) => {
        toast(res.data.message);
        getForum();
        setOpenModal(false);
        await navigate("/");
      })
      .catch((err) => toast(err.response.data.message));
  };
  const handleDraftSubmit = async (e) => {
    e.preventDefault();
    const token = await localStorage.getItem("token");
    if (!title) {
      toast("Enter Title");
    } else if (!category) {
      toast("Select Category");
    } else if (!data.subCategory) {
      toast("Select Subcategory");
    } else if (!description) {
      toast("Enter Description");
    } else {
      postRequest(
        "forum/createdraft",
        { ...data, category, title: title, description: description },
        { token }
      )
        .then(async (res) => {
          toast(res.data.message);
          getDraftForum();
          setOpenModal(false);
          await navigate("/draft");
        })
        .catch((err) => toast(err.response.data.message));
    }
  };
  return (
    <>
      <span onClick={() => setOpenModal(true)}>{props?.children}</span>
      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Body>
          <div className="space-y-2">
            <div className="row m-2 create-topic">
              <div className="mb-3 col-12">
                <input
                  id="title"
                  name="title"
                  className="form-control"
                  placeholder="Title"
                  required={true}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 gap-4">
                <select
                  name="category"
                  id="category"
                  className="form-control"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Category
                  </option>
                  {mainCategories.map((e) => {
                    return <option value={e.name}>{e.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-lg-6 col-md-6 pt-4 pt-lg-0">
                <select
                  name="subCategory"
                  id="subCategory"
                  className="form-control"
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select Subcategory
                  </option>
                  {category == "Frontend"
                    ? frontEndCategories.map((e) => {
                        return <option value={e}>{e}</option>;
                      })
                    : category == "Backend"
                    ? backendCategories.map((e) => {
                        return <option value={e}>{e}</option>;
                      })
                    : category == "Machine Learning"
                    ? machineLearningCategories.map((e) => {
                        return <option value={e}>{e}</option>;
                      })
                    : category == "Artificial Intelligence"
                    ? machineLearningCategories.map((e) => {
                        return <option value={e}>{e}</option>;
                      })
                    : category == "Mobile Application"
                    ? mobileApplicationDevelopmentCategories.map((e) => {
                        return <option value={e}>{e}</option>;
                      })
                    : ""}
                </select>
              </div>
            </div>

            <div className="m-3 ">
              <ReactQuill
                theme="snow"
                name="description"
                value={description}
                onChange={setDescription}
                placeholder="What’s your question?"
              />
            </div>
            <div className="flex items-center justify-end p-6 space-x-2  border-gray-200 rounded-b dark:border-gray-600">
              <button
                data-modal-hide="default-modal"
                type="button"
                className=" bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 outline-btn"
                onClick={handleDraftSubmit}
              >
                Draft
              </button>
              <button
                data-modal-hide="default-modal"
                type="button"
                onClick={handleSubmit}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 purple-btn"
              >
                Post
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Main modal */}
    </>
  );
}
