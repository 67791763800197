import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import CreateTopic from "../Pages/CreateTopic";
import CreateAccount from "../Pages/CreateAccount";
import Topic from "../Pages/Forum";
import Login from "../Pages/Login";
import Category from "../Pages/Category";
import MyQuestion from "../Pages/MyQuestion";
import Search from "../Pages/Search";
import SaveQuestion from "../Pages/SaveQuestion";
import PrivateRoute from "./PrivateRoutes";
import Profile from "../Pages/Profile";
import { useDispatch } from "react-redux";
import { getRequest } from "../Helper/httpProtocol";
import AllCategories from "../Pages/AllCategory";
import MyAnswer from "../Pages/MyAnswer";
import MyFollower from "../Pages/MyFollower";
import MyFollowing from "../Pages/MyFollowing";
import EditProfile from "../Pages/EditProfile";
import Trending from "../Pages/Trending";
import Draft from "../Pages/Draft";
import Saved from "../Pages/Saved";
import About from "../Pages/About";
import Contact from "../Pages/Contact";


export default function Index() {
  const dispatch = useDispatch();
  const getToken = async () => {
    const token = await localStorage.getItem("token");
    await getRequest("auth/user", { token }).then(async (user) => {
      dispatch({ type: "user", user: user.data.data });
    });
    await dispatch({ type: "token", token });
  };
  useEffect(() => {
    getToken();
  }, [dispatch]);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/topic/:id" element={<Topic />} />
        <Route path="/all-categories" element={<AllCategories />} />
        <Route path="/category/:id" element={<Category />} />
        <Route path="/search/:id" element={<Search />} />
        <Route path="/trending" element={<Trending />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        <Route element={<PrivateRoute />}>
          <Route path="/create-topic" element={<CreateTopic />} />
          <Route path="/myquestion" element={<MyQuestion />} />
          <Route path="/save-question" element={<SaveQuestion />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/myanswer" element={<MyAnswer />} />
          <Route path="/myfollower" element={<MyFollower />} />
          <Route path="/myfollowing" element={<MyFollowing />} />
          <Route path="/draft" element={<Draft />} />
          <Route path="/saved" element={<Saved />} />
        </Route>
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}
