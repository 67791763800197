import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../../../Helper/httpProtocol";
import DefaultImage from "../../../Assets/images/default.png";
import { Icon } from "@iconify/react";
export default function Index({ answerId, getComment, refId }) {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");
  const createComment = async (e) => {
    e.preventDefault();
    await postRequest(
      "forum/createcomment",
      { answerId, comment: answer },
      { token }
    )
      .then((res) => {
        toast(res.data.message);
        setAnswer("");
        getComment();
      })
      .catch(async (err) => {
        toast(err.response.data.message);
        await navigate("/login");
      });
  };
  return (
    <>
      <div className="questions-card ps-5 ">
        <div className=" w-100">
          <div style={{ width: "100%", display: "flex", alignItems: 'center', gap: "3px" }}>
            <div>
              {user && user.photoURL ? (
                <img src={user.photoURL} alt="" />
              ) : (
                <img src={DefaultImage} alt="" />
              )}
            </div>

            <div className="ms-3 my-1 w-100 position-relative">
              <input
                type="text"
                className="comment-area radius-5"
                placeholder={`${refId == "reply" ? "Add a reply.." : "Add a comment.."
                  }.`}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
              <div className="post-icon" onClick={createComment}>
                <Icon icon="ant-design:send-outlined" />
              </div>

            </div>
          </div>

        </div>
      </div>


    </>
  );
}
