import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRequest } from "../../../Helper/httpProtocol";
export default function SidebarRight() {
  const [topCategories, setTopCategories] = useState([]);
  const [todayTopCategories, setTodayTopCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await getRequest("forum/fetchtopcategories");
      const todayCategories = await getRequest("forum/fetchtodaytopcategories");
      setTodayTopCategories(todayCategories?.data);
      setTopCategories(categories?.data);
    };
    fetchCategories();
  }, []);

  return (
    <div className="sidebarRight ">
      <ul class="list-group" style={{ flexDirection: "column" }}>
        <li class="list-group-item  trending-head mt-3 "> Trending Today</li>
        {typeof todayTopCategories == "object" ? todayTopCategories?.map((item, index) => {
          return <li class="list-group-item " key={index}>
            <b className="color-purple ">#{index + 1}</b>
            <span className="sidebar-right-span">{item?.category}</span>
          </li>
        }) : null}

        <li class="list-group-item  trending-head mt-3"> Top Categories</li>
        {typeof topCategories == "object" ? topCategories?.map((item, index) => {
          return <Link to={`/category/${item?.category?.toLowerCase()}`}>
            <li class="list-group-item answer-item ">
              <div className="d-flex" key={index}>
                {/* <img src={DefaultImage} className="answer-item-left" /> */}

                <div className="text-start ms-2 text-dark">
                  {" "}
                  {item?.category}
                  <div className="answer-no">
                    {item?.questionCount} <span> Question</span>
                  </div>
                </div>
              </div>
              {/* <img className="answer-item-right" src={AnswerImage} /> */}
            </li>
          </Link>
        }) : null}
      </ul>
    </div>
  );
}
