import React, { useEffect, useState } from "react";
import CategoriesBlock from "../../Components/Box/Forum/CategoryBlock";
import SidebarLeft from "../../Components/Sidebar/SidebarLeft";
import Header from "../../Layout/Header";
import { getRequest } from "../../Helper/httpProtocol";
import ContentLoader from "react-content-loader";
export default function AllCategories() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      const todayCategories = await getRequest("forum/fetchtopcategories");
      setCategories(todayCategories?.data);
    };
    fetchCategories();
  }, []);
const MyLoader = () => (
  <ContentLoader width={476} height={124}>
    <rect x="0" y="41" rx="3" ry="3" width="157" height="6" />
    <rect x="0" y="56" rx="0" ry="0" width="170" height="6" />
    <rect x="0" y="72" rx="0" ry="0" width="175" height="6" />
    <rect x="0" y="88" rx="0" ry="0" width="178" height="26" />
    <rect x="0" y="21"  width="130" height="8" />
  </ContentLoader>
);

  return (
    <div className="container-fluid">
      <Header />
      {/* <Pagination /> */}
      <div className="container">
        <div className="d-flex pt-52">
          <div className="d-none d-md-block" style={{ width: "325px" }}>
            <SidebarLeft />
          </div>
          <div className="w-100">
            <div className="row p-3 categories-card">
              <h3 className="my-3">Categories</h3>

              {categories?.length ===0 ? <MyLoader /> : categories?.map((item, index) => {
                return (
                  <>
                    
                    <CategoriesBlock
                      key={index}
                      title={item?.category}
                      //           detail="Frontend, in the context of web development, refers to the part of a
                      // website or web application that the user interacts with directly. It
                      // encompasses the visual elements that users see in their web browsers."
                      path={`/category/${item?.category?.toLowerCase()}`}
                    />
                  </>
                );
              })}

             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
