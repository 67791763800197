"use client";
import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { postRequest } from "../../Helper/httpProtocol";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
export default function Report({ children, questionId }) {
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState('');
  const token = useSelector(state => state.token)
  const createReport = async () => {
    const data = await postRequest('forum/report', { questionId, message }, { token: token ?? localStorage.getItem("token") })
    if (data?.data?.data) {
      toast.success(data?.data?.message);
      setMessage("")
      setOpenModal(false)
    } else {
      toast.error(data?.data?.message)
    }
  }

  return (
    <>
      <div onClick={() => setOpenModal(true)}>{children}</div>
      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Body>
          <div className="space-y-2">
            <textarea
              cols={3}
              onChange={(event) => setMessage(event.target.value)}
              rows={5}
              className="report-area"
              placeholder="Message"
            />
            <div className="flex items-center justify-end  space-x-2  border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={createReport}
                data-modal-hide="default-modal"
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 purple-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
