import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDrZMSmSMKYIMndTUT8uTVmw1PzEzkra7Y",
  authDomain: "jspedia.firebaseapp.com",
  projectId: "jspedia",
  storageBucket: "jspedia.appspot.com",
  messagingSenderId: "87542240606",
  appId: "1:87542240606:web:ad9cd3fd5a39a0484f6bcf",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
