import { Card } from "flowbite-react";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRequest } from "../../../Helper/httpProtocol";

export default function Index() {
  const [forums, setForums] = useState([]);
  const token = useSelector((state) => state.token);
  const getUserForum = async () => {
    getRequest("forum/usercreatedforum", { token: token }).then((res) => {
      setForums(res.data.data);
    });
  };
  useEffect(() => {
    getUserForum();
  }, [forums, token]);
  return (
    <div className=" mt-5">
      <Card className="w-100 border-primary">
        <div className=" flex items-center justify-between">
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
            Active Threads
          </h5>
          <a
            href="#"
            className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 text-decoration-none"
          >
            View all
          </a>
        </div>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700 p-0 m-0">
            {forums.map((e) => {
              return (
                <li className="py-1">
                  <div className="flex items-center space-x-4">
                    {/* <div className="shrink-0">
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
                        alt="Neil image"
                      />
                    </div> */}
                    <div className="min-w-0 flex-1 pt-2">
                      <Link
                        className="no-underline hover:decoration-solid"
                        to={`/topic/${e._id}`}
                      >
                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                          {e.title}
                        </p>
                      </Link>
                      {/* <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                        email@windster.com
                      </p> */}
                    </div>
                    {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {getCount(e)}
                    </div> */}
                  </div>
                </li>
                // <li>
                //   <Link to={`/category/${e?.replace(" ", "-")?.toLowerCase()}`}>
                //     {e}
                //     <span className="badge pull-right">{getCount(e)}</span>
                //   </Link>
                // </li>
              );
            })}
          </ul>
        </div>
      </Card>
    </div>
  );
}
