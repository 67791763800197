import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import DefaultImage from "../../Assets/images/default.png";
import QuestionsCard from "../../Components/Card/question-card";
import SidebarLeft from "../../Components/Sidebar/SidebarLeft";
import SidebarRight from "../../Components/Sidebar/SidebarRight";
import Header from "../../Layout/Header";
import "../../Style//Custom/custom.css";
import "../../Style/style.css";
import { getRequest } from "../../Helper/httpProtocol";
import { useDispatch, useSelector } from "react-redux";

export default function Index() {
  const forumsDraft = useSelector((state) => state.forumsDraft);
  const totalDraftForums = useSelector((state) => state.totalDraftForums);

  const dispatch = useDispatch();
  const getDraftForum = async () => {
    const token = await localStorage.getItem("token");
    getRequest("forum/getalldraft?take=5&skip=0", { token })
      .then(async (res) => {
        await dispatch({ type: "forumsDraft", forumsDraft: res.data.data });
        await dispatch({
          type: "totalDraftForums",
          totalDraftForums: res.data.total,
        });
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getDraftForum();
  }, []);
  return (
    <>
      <div>
        <Header />

        <div className="container ">
          <div className="row pt-52">
            <div className=" col-12 col-lg-3 ">
              <SidebarLeft />
            </div>
            <div className=" col-12 col-lg-6 px-lg-0 edit-profile ">
              <div className="pt-3">
                <h4 className="heading-border radius-5">
                  Draft{" "}
                  <span className="light-color">
                    {" "}
                    {`(${totalDraftForums})`}
                  </span>{" "}
                </h4>{" "}
                {forumsDraft?.map((item, index) => {
                  return (
                    <QuestionsCard
                      key={index}
                      src={item?.userId?.photoURL ?? DefaultImage}
                      title={item?.title}
                      description={item?.description}
                      userId={item?.userId}
                      id={item?._id}
                      category={item?.category}
                      subCategory={item?.subCategory}
                      getDraftForum={getDraftForum}
                    />
                  );
                })}
              </div>
            </div>
            <div className=" col-12 col-lg-3 mb-lg-0 mb-5 pb-lg-0 pb-5 ">
              <SidebarRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
