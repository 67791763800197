import { Icon } from "@iconify/react";
import { Dropdown } from "flowbite-react";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { Link, useNavigate } from "react-router-dom";
import timeAgo from "../../Helper/time-duration";
import defaultImage from "../../Assets/images/default.png";
import Answer from "../../Components/Box/Forum/Answer";
import ReplyTopic from "../../Components/Form/Reply";
import { getRequest, postRequest } from "../../Helper/httpProtocol";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Report from "../Modal/report";

const MyLoader = () => (
    <ContentLoader>
        <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
        <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
        <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
    </ContentLoader>
);

export default function Index({ forum: {
    _id,
    user,
    title,
    description,
    upvotes,
    downvotes,
    currentUserVote,
    category,
    createdAt,
    subCategory,
} }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.user);
    const saveQuestionData = useSelector((state) => state.saveQuestion);
    const token = localStorage.getItem("token");
    const [questionLength, setQuestionLength] = useState();
    const [votesCount, setVotesCount] = useState({ up: 0, down: 0 });
    const [voteType, setVoteType] = useState(null);
    const [limitedData, setLimitedData] = useState([]);

    useEffect(() => {
        setVotesCount({ up: upvotes, down: downvotes });
        setVoteType(currentUserVote);
        getAllAnswer();
    }, []);

    const getAllAnswer = async () => {
        try {
            const res = await getRequest("forum/getanswers", { questionId: _id, userId: sessionUser._id });
            setQuestionLength(res.data.data);
            setLimitedData(res.data.data?.slice(0, 2));
        } catch (error) {
            console.error("Error fetching answers:", error);
        }
    };

    const getSavedQuestion = async () => {
        try {
            const res = await getRequest("forum/savequestion?take=5&skip=0", { token });
            dispatch({
                type: "saveQuestion",
                saveQuestion: res.data.data,
            });
            dispatch({
                type: "saveQuestionTotal",
                saveQuestionTotal: res.data.total,
            });
        } catch (error) {
            console.error("Error fetching saved questions:", error);
        }
    };

    const saveQuestion = async () => {
        if (token) {
            try {
                const res = await postRequest(
                    "forum/savequestion",
                    { questionId: _id },
                    { token }
                );
                getSavedQuestion();
                toast(res.data.message);
            } catch (error) {
                console.error("Error saving question:", error);
            }
        } else navigate("/login");
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(`${window.location.origin}/topic/${_id}`);
        toast("Copied");
    };

    const createVote = async (vote = null) => {
        if (vote) setVotesCount(prev => ({
            ...(voteType ? { [voteType]: prev[voteType] - 1 } : prev),
            [vote]: prev[vote] + 1
        }));
        if (vote === voteType) setVoteType(null);
        else setVoteType(vote);
        try {
            const res = await postRequest(
                "forum/vote",
                { questionId: _id, vote },
                { token }
            );
            toast(res.data.message);
        } catch (error) {
            console.error("Error creating vote:", error);
        }
    };
    const someFunction = () => {
        getAllAnswer()
    }

    return (
        <section key={_id}>
            <div className="questions-card p-3">
                <div className="d-flex">
                    <div style={{ width: "35px" }}>
                        {user?.photoURL ? <img src={user?.photoURL} alt="" /> : <img src={defaultImage} alt="" />}
                    </div>
                    <div className="mx-3 w-100">
                        <div className="w-full flex justify-between">
                            <span className="user_name">
                                {`${user?.firstName} ${user?.lastName}`}
                                {sessionUser?._id === user?._id && <span className="user_name_me">(me)</span>}
                            </span>
                            <span style={{ fontSize: "10px", color: "#939393", fontWeight: "400" }}>{timeAgo(createdAt)}</span>
                        </div>
                        <span>
                            <Link className="no-underline question-text" to={`/topic/${_id}`}>
                                <span className="ms-0">{title}</span>
                            </Link>
                            <p className="description-para d-inline-block mb-0">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: description?.slice(0, 200),
                                    }}
                                ></span>
                                {description?.length > 200 && (
                                    <>
                                        ...
                                        <Link to={`/topic/${_id}`} className="read-more">
                                            Read More
                                        </Link>
                                    </>
                                )}
                            </p>
                        </span>
                        <div className="icon-div">
                            <div className="d-flex" onClick={() => createVote("up")}>
                                {voteType === "up" ? <Icon icon="ph:arrow-fat-up-fill" /> : <Icon icon="ph:arrow-fat-up-light" />}
                                <span>{votesCount.up}</span>
                            </div>
                            <div className="d-flex" onClick={() => createVote("down")}>
                                {voteType === "down" ? <Icon icon="ph:arrow-fat-down-fill" className="ms-3" /> : <Icon icon="ph:arrow-fat-down-light" className="ms-3" />}
                                <span>{votesCount.down}</span>
                            </div>
                            <Link className="no-underline more-text" to={`/topic/${_id}`}>
                                <Icon icon="heroicons-outline:chat" className="ms-3" />
                            </Link>
                            <span>{questionLength?.length}</span>
                        </div>
                    </div>
                </div>
                <div className="action-div">
                    <div>
                        <Dropdown
                            arrowIcon={false}
                            inline
                            className="profile-drop"
                            label={<Icon icon="bi:three-dots" />}
                        >
                            {token && (
                                <Report questionId={_id}>
                                    <Dropdown.Item>Report</Dropdown.Item>
                                </Report>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleCopy}>Copy Link</Dropdown.Item>
                            <Dropdown.Divider />
                            {token && <Dropdown.Item>Not Interested</Dropdown.Item>}
                        </Dropdown>
                    </div>
                    <div>
                        {saveQuestionData?.find((item) => item?._id === _id) ? (
                            <Icon
                                icon="ic:outline-bookmark"
                                className="color-purple cursor-pointer"
                                onClick={saveQuestion}
                            />
                        ) : (
                            <Icon
                                icon="mdi:bookmark-outline"
                                className="color-purple cursor-pointer"
                                onClick={saveQuestion}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="px-3 pt-1 mt-1 bg-white pb-lg-2 pb-2">
                <ReplyTopic questionId={_id} getAllAnswers={someFunction} />
                {questionLength === undefined ? (
                    <>
                        <MyLoader />
                        <MyLoader />
                    </>
                ) : (
                    questionLength?.length > 1 &&
                    limitedData?.map((item, index) => (
                        <Answer
                            key={index}
                            id={item?._id}
                            userId={item?.userId}
                            answer={item?.answer}
                        />
                    ))
                )}
                {questionLength?.length > 1 && (
                    <Link className="no-underline more-text ms-5 " to={`/topic/${_id}`}>
                        View more answers...
                    </Link>
                )}
            </div>
        </section>
    );
}
