import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../../../Helper/httpProtocol";
import { Icon } from "@iconify/react";
import DefaultImage from "../../../../Assets/images/default.png";
export default function Index({ comment, createdAt, replyId, prevAnswerId }) {
  const [upIcon, setUpIcon] = useState(false);
  const [downIcon, setDownIcon] = useState(false);
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [votesCount, setVotesCount] = useState({ up: 0, down: 0 });
  const [upVote, setUpVote] = useState(0);
  const [downVote, setDownVote] = useState(0);
  const [prevAnswer, setPrevAnswer] = useState();
  const getComment = async () => {
    await getRequest("forum/getcomment", { prevAnswerId }).then((res) => {
      setPrevAnswer(res.data.data);
    });
  };
  const createVote = async (vote = null) => {

    if ((vote === "up" && upIcon) || (vote === "down" && downIcon)) {
      setUpIcon(false)
      setDownIcon(false)
    } else if (vote === "up") setUpIcon(true)
    else if (vote === "down") setDownIcon(true)

    await postRequest(
      "forum/createupvote",
      { questionId: replyId, vote },
      { token }
    ).then((res) => {
      toast(res.data.message);
      getUpVote();
    });
  };
  const createUpVote = async () => {
    setUpIcon(!upIcon);
    await postRequest(
      "forum/createupvote",
      { questionId: replyId },
      { token }
    ).then((res) => {
      toast(res.data.message);
      getUpVote();
    });
  };
  const createDownVote = async () => {
    setDownIcon(!downIcon);
    await postRequest(
      "forum/createdownvote",
      { questionId: replyId },
      { token }
    ).then((res) => {
      toast(res.data.message);
      getDownVote();
    });
  };

  const getUpVote = async () => {
    await getRequest("forum/getupvote", { questionId: replyId }).then((res) => {
      setUpVote(res.data.count);
      const isLike = res.data?.data?.some(item => item?.userId === user?._id);
      setUpIcon(isLike);
    });
  };
  const getDownVote = async () => {
    await getRequest("forum/getdownvote", { questionId: replyId }).then(
      (res) => {
        setDownVote(res.data.count);
        const isDislike = res.data?.data?.some(item => item?.userId === user?._id);
        setDownIcon(isDislike);
      }
    );
  };
  useEffect(() => {
    getUpVote();
    getDownVote();
    getComment();
  }, []);
  return (
    <>
      <div className="questions-card ps-5 tree_label_3_side">
        {" "}
        <div className=" w-100 ps-5">
          <div className="d-flex  w-100 tree_label_3">
            {/* {userData && userData.photoURL ? (
              <img src={userData.photoURL} alt="" />
            ) : (
              <img src={DefaultImage} alt="" />
            )} */}
            <img src={DefaultImage} alt="" />

            <div className="ms-3 m-1 p-2 me-0 w-100 bg-light-purple radius-5">
              <p>
                {" "}
                <p className="answer-text">{comment} 3</p>
              </p>
              <div className="icon-div ">
                <div className="d-flex" onClick={() => createVote("up")}>
                  {" "}
                  {upIcon ? <Icon icon="ph:arrow-fat-up-fill" /> : <Icon icon="ph:arrow-fat-up-light" />}
                  {" "}
                  <span>{upVote}</span>
                </div>{" "}
                <div className="d-flex" onClick={() => createVote("down")}>
                  {" "}
                  {downIcon ? <Icon icon="ph:arrow-fat-down-fill" className="ms-3" /> : <Icon icon="ph:arrow-fat-down-light" className="ms-3" />}
                  {" "}
                  <span> {downVote}</span>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="post"> */}
        {/* <div className="topwrap">
          <div className="userinfo pull-left">
            <div className="avatar">
              <img src="images/avatar3.jpg" alt="" />
              <div className="status red">&nbsp;</div>
            </div>

            <div className="icons">
              <img src="images/icon3.jpg" alt="" />
              <img src="images/icon4.jpg" alt="" />
              <img src="images/icon5.jpg" alt="" />
              <img src="images/icon6.jpg" alt="" />
            </div>
          </div>
          <div className="posttext pull-left">
            <blockquote>
              <span className="original">Original Posted by - theguy_21:</span>
              {prevAnswer?.comment}
            </blockquote>
            <p>{comment}</p>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="postinfobot">
          <div className="likeblock pull-left">
            <span
              style={{ cursor: "pointer" }}
              className="up"
            >
              <i className="fa fa-thumbs-o-up"></i>
              {upVote}
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={createDownVote}
              className="down"
            >
              <i className="fa fa-thumbs-o-down"></i>
              {downVote}
            </span>
          </div>

          <div className="prev pull-left">
            <span style={{ cursor: "pointer" }} onClick={() => {}}>
              <i className="fa fa-reply"></i>
            </span>
          </div>
          <div className="posted pull-left">
            <i className="fa fa-clock-o"></i> Posted on :{" "}
            {moment(createdAt).fromNow()}
          </div>

          <div className="next pull-right">
            <a href="#">
              <i className="fa fa-share"></i>
            </a>

            <a href="#">
              <i className="fa fa-flag"></i>
            </a>
          </div>

          <div className="clearfix"></div>
        </div> */}
        {/* {replyComment ? <CommentForum refId={"reply"} /> : null} */}
        {/* </div> */}
      </div>
    </>
  );
}
