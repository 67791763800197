import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import Header from "../../Layout/Header";
import SideBar from "../../Layout/SideBar";
import "../../Style//Custom/custom.css";
import "../../Style/style.css";

import NewTopicForm from "../../Components/Form/NewTopic";
export default function Index() {
  return (
    <div className="container-fluid">
      <Header />
      <div className="container">
        <div className="row">
          <NewTopicForm />
          <SideBar refId={"newcreate"} />
        </div>
      
      </div>
    </div>
  );
}
