import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Post from "../../Layout/Post";
import SideBar from "../../Layout/SideBar";

import Pagination from "../../Layout/Pagination";
import { getRequest } from "../../Helper/httpProtocol";
import { mainCategories } from "../../Config/Consent";
import { useSelector } from "react-redux";
export default function Index() {
  const [forum, setForum] = useState([]);
  return (
    <div className="container-fluid">
      <Header />
      <div className="container">
        <div className="row">
          <Post data={forum} />
          <SideBar />
        </div>
      </div>
      {/* <Pagination /> */}
    
    </div>
  );
}
