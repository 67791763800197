import { legacy_createStore as createStore } from 'redux';
const initialState = {
    token: "",
    user: {},
    forums: [],
    totalForums: 0,
    answers: [],
    forumsDraft: [],
    totalDraftForums: 0,
    userCreatedForum: [],
    userCreatedForumTotal: 0,
    saveQuestion: [],
    saveQuestionTotal: 0,
};

const Reducers = (state = initialState, action) => {
    switch (action.type) {
        case "token":
            return {
                ...state,
                token: action.token,

            };
        case "user":
            return {
                ...state,
                user: action.user,
            };
        case "forums":
            return {
                ...state,
                forums: action.forums,
            };
        case "totalForums":
            return {
                ...state,
                totalForums: action.totalForums,
            };
        case "forumsDraft":
            return {
                ...state,
                forumsDraft: action.forumsDraft,
            };
        case "totalDraftForums":
            return {
                ...state,
                totalDraftForums: action.totalDraftForums,
            };
        case "userCreatedForum":
            return {
                ...state,
                userCreatedForum: action.userCreatedForum,
            };
        case "userCreatedForumTotal":
            return {
                ...state,
                userCreatedForumTotal: action.userCreatedForumTotal,
            };
        case "saveQuestion":
            return {
                ...state,
                saveQuestion: action.saveQuestion,
            };
        case "saveQuestionTotal":
            return {
                ...state,
                saveQuestionTotal: action.saveQuestionTotal,
            };
        case "answers":
            return {
                ...state,
                answers: action.answers,
            };
        default:
            return state;
    }
};
const store = createStore(Reducers);

export default store;
