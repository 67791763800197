import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Post from "../../Layout/Post";
import SidebarProfile from "../../Components/Sidebar/SidebarProfile";
import ProfileCard from "../../Components/Card/profile-card";
import { getRequest } from "../../Helper/httpProtocol";
import { mainCategories } from "../../Config/Consent";
import { useSelector } from "react-redux";
import AnswerCard from "../../Components/Card/answer-card";
export default function MyAnswer() {
  return (
    <div className="container-fluid">
      <div className="">
        <Header />
        <div className="container">
          <div className="d-flex">
            <div className="d-none d-lg-block" style={{ width: " 300px" }}>
              <SidebarProfile />
            </div>
            <div className="w-100 pt-52 mt-3 mb-5">
              <div className="bg-white p-3">
                <ProfileCard />
              </div>
              <h4 className="heading-border radius-5">
                Your Answer <span className="light-color"> (28)</span>{" "}
              </h4>{" "}
              <AnswerCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
