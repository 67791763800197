import { Icon } from "@iconify/react";
import { Dropdown } from "flowbite-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../Helper/httpProtocol";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CreateTopic from "../Modal/create-topic";
export default function QuestionsCard(props) {
  const {
    src,
    title,
    userId,
    id,
    getDraftForum,
    description,
    category,
    subCategory,
    status,
  } = props;
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const draftToPublish = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await postRequest(
        "forum/drafttopublish",
        { questionId: id },
        { token }
      ).then((res) => {
        getDraftForum();
        toast(res.data.message);
      });
    } else navigate("/login");
  };

  return (
    <>
      <div className="questions-card p-3">
        <div className="d-flex">
          {" "}
          <div>
            <img src={src}></img>
          </div>
          <div className="mx-3">
            <div>
              <span className="user_name">Whateverrrr{`${userId?.firstName} ${userId?.lastName}`}</span>
              {userId?._id === user?._id && <span className="user_name_me">(me)</span>}
            </div>
            <span>{title} </span>
            <span
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></span>
          </div>
        </div>
        <div>
          <Dropdown
            arrowIcon={false}
            inline
            className="profile-drop"
            label={<Icon icon="bi:three-dots" />}
          >
            <CreateTopic
              description={description}
              title={title}
              questionId={id}
              category={category}
              subCategory={subCategory}
            >
              <Dropdown.Item>Edit</Dropdown.Item>
            </CreateTopic>
            {status !== "Published" && (
              <Dropdown.Item onClick={draftToPublish}>Publish</Dropdown.Item>
            )}
          </Dropdown>
        </div>
      </div>
    </>
  );
}
