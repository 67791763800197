
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
export default function SidebarLeft() {

  return (
    <div className="sidebarLeft">
      <ul class="list-group">
        <Link to="/all-categories" style={{ marginTop: "10px" }}>
          {" "}
          <li class="list-group-item" >
            <Icon icon="ion:list" />
            <span> Categories</span>
          </li>
        </Link>
        <Link to="/trending" style={{ marginTop: "10px" }}>
          {" "}
          <li class="list-group-item ">
            <Icon icon="ri:fire-line" />
            <span> Trending </span>
          </li>
        </Link>
        <Link to="/draft" style={{ marginTop: "10px" }}>
          {" "}
          <li class="list-group-item " >
            <Icon icon="carbon:result-draft" />
            <span> Draft</span>
          </li>
        </Link>
        {/* <Link to="/saved">
          {" "}
          <li class="list-group-item ">
            <Icon icon="akar-icons:ribbon" />
            <span> Saved</span>
          </li>
        </Link> */}
      </ul>
    </div>
  );
}
