import { Card } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { mainCategories } from "../../../Config/Consent";
import { getRequest } from "../../../Helper/httpProtocol";
export default function Index() {
  const [forums, setForums] = useState([]);
  const getForum = async () => {
    getRequest("forum/getall")
      .then((res) => {
        setForums(res.data.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    // getForum();
  }, [forums]);
  const getCount = (category) => {
    const count = forums.filter((e) => e.category == category);
    return count.length;
  };
 
  return (
    <div className="">
      <Card className=" card  border-primary">
        <div className=" flex items-center justify-between">
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
            Categories 
          </h5>
          {/* <div><a
            href="#"
            className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 text-decoration-none"
          >
            View all
          </a>
          </div> */}
          
        

        </div>
        <div className="flow-root">
          <ul className="  p-0 m-0">
            {mainCategories.map((e) => {
             
              return (
                <li className="py-1 border-bottom border-primary">
                  <div className="flex items-center space-x-4">
                    <div className="shrink-0 ">
                    <img src={`${e.icon}`} alt="" width={'22px'} />
                    </div>
                    <div className="min-w-0 flex-1 pt-3">
                      <Link
                        className="no-underline hover:decoration-solid"
                        to={`/category/${e.name?.replace(" ", "-")?.toLowerCase()}`}
                      >
                        <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                        {e.name}
                         
                        </p>
                      </Link>
                      {/* <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      email@windster.com
                    </p> */}
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {getCount(e.name)}
                    </div>
                  </div>
                </li>
                // <li>
                //   <Link to={`/category/${e?.replace(" ", "-")?.toLowerCase()}`}>
                //     {e}
                //     <span className="badge pull-right">{getCount(e)}</span>
                //   </Link>
                // </li>
              );
            })}
          </ul>
        </div>
      </Card>
    
    </div>
  );
}
