import Frontend from "../../Assets/images/front-end.png"
import Backend from "../../Assets/images/backend.png"
import MachineLearning from "../../Assets/images/machine-learning.png"
import ArtificalIntelegence from "../../Assets/images/ai.png"
import MobileApplication from "../../Assets/images/mobile-application.png"
const mainCategories = [
  {
    name:  "Frontend",
    icon:Frontend
  },
  {
    name:  "Backend",
    icon:Backend
  },
 
  {
    name:  "Machine Learning",
    icon: MachineLearning
  },
  {
    name: "Artificial Intelligence",
    icon:ArtificalIntelegence
  },
  {
    name: "Mobile Application",
    icon: MobileApplication
  },
  
];

const frontEndCategories = [
  "React JS",
  "Angular JS",
  "Vue JS",
  "Ember JS",
  "Polymer JS",
  "Backbon JS",
  "Aurelia JS",
];
const backendCategories = [
  "Express JS",
  "Node JS",
  "Next JS",
  "Meteor",
  "Koa",
  "Sails",
  "Mithril JS",
  "Webix",
];

const machineLearningCategories = [
  "Brain JS",
  "TensorFlow JS",
  "Mind JS",
  "ConvNetJS",
  "ML5 JS",
  "Neuro JS",
  "Keras JS",
];

const mobileApplicationDevelopmentCategories = [
  "Sencha ExtJS",
  "React Native",
  "Ionic",
  "Mobile Angular UI",
  "Apache Cordova",
  "jQuery Mobile",
  "Ember JS",
];

export {
  mainCategories,
  frontEndCategories,
  backendCategories,
  machineLearningCategories,
  mobileApplicationDevelopmentCategories,
};
