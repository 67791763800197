import React, { useEffect, useState } from "react";
import Reply from "../../Forum/Reply";
import CommentForm from "../../../Form/Comment";
import { getRequest } from "../../../../Helper/httpProtocol";
 import ContentLoader from "react-content-loader";
export default function Index({ answerId }) {
  const [comments, setComments] = useState([]);
  const getComment = () => {
    getRequest("forum/comment", { answerId }).then((payload) => {
      setComments(payload.data.data);
    });
  };
  useEffect(() => {
    getComment();
  }, []);
 const MyLoader = () => (
   <ContentLoader>
     <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
     <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
     <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
     <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
     <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
   </ContentLoader>
 );
  return (
    <>
      {comments === undefined ? (
        <div>
          {" "}
          <MyLoader className="mt-3" />
     
    
          
        </div>
      ) : (
        comments?.map((e, i) => {
          return (
            <Reply
              key={i}
              replyId={e._id}
              text={e.comment}
              userId={e.userId}
              prevAnswerId={answerId}
              answerId={e.answerId}
              createdAt={e.createdAt}
            />
          );
        })
      )}
      <div className="label_2_commment">
      
        <CommentForm answerId={answerId} getComment={getComment} />
      </div>
    </>
  );
}
