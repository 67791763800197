import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postRequest } from "../../../Helper/httpProtocol";
import { Link, useNavigate } from "react-router-dom";
import { getRequest } from "../../../Helper/httpProtocol";
import { PiNumberCircleTwoFill } from "react-icons/hi";
import { Icon } from "@iconify/react";
import DefaultImage from "../../../Assets/images/default.png";
export default function Index({
  questionId,
  getAllAnswers,
  EditAnswer,
}) {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  const [answer, setAnswer] = useState("");
  const createAnswer = async (e) => {
    e.preventDefault();
    if (user) {
      postRequest("forum/createanswer", { questionId, answer }, { token })
        .then((res) => {
          getAllAnswers();

          toast(res.data.message);
          setAnswer("");

        })
        .catch(async (err) => {
          console.log(err);
          toast(err?.response?.data?.message);
        });
    } else {
    }
  };

  return (
    <>
      {" "}
      <div className="questions-card " id="replay">
        <div className=" w-100">
          <div className="" style={{ height: "85px", gap: "3px", display: "flex", alignItems: "center", width: "100%" }}>
            <div>
              {user && user.photoURL ? (
                <img src={user.photoURL} alt="" />
              ) : (
                <img src={DefaultImage} alt="" />
              )}
            </div>

            <div style={{ width: "100%" }} className=" position-relative">
              <input
                type="text"
                className="comment-area radius-5 "
                style={{ width: "100%" }}
                placeholder="Write your answer"
                value={EditAnswer === "" ? answer : EditAnswer}
                onChange={(event) => setAnswer(event.target.value)}
              />
              <div className="post-icon" onClick={createAnswer}>
                <Icon icon="ant-design:send-outlined" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
