import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Style/style.css";
import "../../Style//Custom/custom.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import defaultImage from "../../Assets/images/default.png";
import { getRequest, postRequest } from "../../Helper/httpProtocol";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function ProfileCard() {
  const [forum, setForum] = useState([]);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [editModal, setEditModal] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [interest, setInterest] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const getForum = async () => {
    getRequest("forum/getall")
      .then((res) => {
        setForum(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    updatedData[event.target.name] = event.target.value;
    setUpdatedData(updatedData);
  };
  const updateProfile = async () => {
    await postRequest(
      "auth/update",
      { firstName, lastName, email, password, interest, phoneNumber },
      { token }
    ).then((res) => {
      toast(res.data.message);
      setEditModal(false);
      window.location.reload();
    });
  };

  // console.log(user)
  return (
    <div className="profile-card p-4 ">
      <div>
        {user && user.photoURL ? (
          <img src={user.photoURL} alt="" />
        ) : (
          <img src={defaultImage} alt="" />
        )}
      </div>
      <div className="ms-3 pt-3">
        <h4>
          {" "}
          {user && user.firstName ? (
            <div>{`${user?.firstName} ${user?.lastName}`}</div>
          ) : (
            <></>
          )}
        </h4>
        <p>{/* 20 Followers <span className="ms-1">20</span> Following */}</p>
      </div>
    </div>
  );
}
