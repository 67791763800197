import React, { useState } from "react";
import Header from "../../Layout/Header";
import { Link } from "react-router-dom";
import { Label } from "flowbite-react";
import { toast } from "react-toastify";

export default function Index() {
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const [data, setData] = useState({})
  const handlechange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }
  const handleSubmit = () => {
    if (!data.firstName) {
      toast.error("Please enter First Name");
      return
    }
    if (!data.lastName) {
      toast.error("Please enter Last Name");
      return
    }
    if (!data.email || !isValidEmail(data.email)) {
      toast.error("Please enter Your Valid Email Name");
      return
    }
    if (!data.message) {
      toast.error("Please enter Message");
      return
    }
    if (data.message.length < 25) {
      toast.error("Message should be at least 25 characters long");
      return;
    }
    toast.success("Succesfully");
    setData({
      firstName: "",
      lastName: "",
      email: "",
      message: ''
    })

  }

  return (
    <div className="container-fluid ">
      <Header />
      <div className="container pt-52 ">
        <div className="row bg-white j-center mt-5 w-75 m-auto ">
          <div className=" col-lg-8 login-card contact-form p-3 my-lg-3 ">
            <h1> Get In Touch</h1>
            <div className="row ">
              <div className="col-lg-6 my-3  ">
                <div className=" block">
                  <Label htmlFor="firstName" value="Your First Name" />
                </div>
                <input
                  className="form-control"
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={data.firstName}
                  onChange={handlechange}
                  placeholder="Sarah"
                  required={true}
                // onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 my-3 ">
                <div className="block">
                  <Label htmlFor="lastName" value="Your Last Name" />
                </div>
                <input
                  className="form-control"
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder="John"
                  onChange={handlechange}
                  value={data.lastName}
                  required={true}
                // onChange={handleChange}
                />
              </div>

              <div className="col-12 mb-3">
                <div className=" block">
                  <Label htmlFor="email" value="Your Email" />
                </div>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  name="email"
                  onChange={handlechange}
                  value={data.email}
                  placeholder="Email"
                  required={true}
                />
              </div>
              <div className="col-12 mb-3">
                <div className=" block">
                  <Label htmlFor="email" value="Message" />
                </div>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  onChange={handlechange}
                  value={data.message}
                  placeholder="Message"
                  required={true}
                  rows={5}
                />
              </div>
            </div>
            <div className="flex items-center justify-end  space-x-2  border-gray-200 rounded-b dark:border-gray-600 mt-3">

              {" "}
              <button
                data-modal-hide="default-modal"
                type="button"
                onClick={() => {
                  handleSubmit()
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 purple-btn"
              >
                Submit
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
