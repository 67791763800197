import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../../../Helper/httpProtocol";
import CommentForum from "../../../Form/Comment";
import DownReply from "../../../Box/Forum/DownReply";
import DefaultImage from "../../../../Assets/images/default.png";
import ContentLoader from "react-content-loader";
export default function Index({
  replyId,
  text,
  answerId,
  prevAnswerId,
  createdAt,
  userId,
}) {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [upIcon, setUpIcon] = useState(false);
  const [downIcon, setDownIcon] = useState(false);
  const [replyComment, setReplyComment] = useState(false);
  const [replies, setReplies] = useState([]);
  const [upVote, setUpVote] = useState(0);
  const [downVote, setDownVote] = useState(0);
  const [userData, setUserData] = useState();
  const getComment = () => {
    getRequest("forum/comment", { answerId: replyId, userId }).then(
      (payload) => {
        setReplies(payload.data.data);
        setUserData(payload.data.data.userId);
      }
    );
  };
  useEffect(() => {
    getComment();
  }, []);

  //===  user data

  const createUpVote = async () => {
    setUpIcon(!upIcon);
    await postRequest(
      "forum/createupvote",
      { questionId: answerId },
      { token }
    ).then((res) => {
      toast(res.data.message);
      getUpVote();
    });
  };
  const createDownVote = async () => {
    setDownIcon(!downIcon);
    await postRequest(
      "forum/createdownvote",
      { questionId: answerId },
      { token }
    ).then((res) => {
      toast(res.data.message);
      getDownVote();
    });
  };

  const getUpVote = async () => {
    await getRequest("forum/getupvote", { questionId: answerId }).then(
      (res) => {
        setUpVote(res.data.count);
        const isLike = res.data?.data?.some(item => item?.userId === user?._id);
        setUpIcon(isLike);
      }
    );
  };
  const getDownVote = async () => {
    await getRequest("forum/getdownvote", { questionId: answerId }).then(
      (res) => {
        setDownVote(res.data.count);
        const isDislike = res.data?.data?.some(item => item?.userId === user?._id);
        setDownIcon(isDislike);
      }
    );
  };
  useEffect(() => {
    getUpVote();
    getDownVote();
  }, [upVote, downVote, answerId]);
  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
      <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
      <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
    </ContentLoader>
  );
  return (
    <>
      <div className="questions-card  ps-5 tree_label_2">
        {" "}
        <div className={`w-100  ${replyComment && "down-line-2"}`}>
          <div className="d-flex  w-100">
            {userData && userData.photoURL ? (
              <img src={userData.photoURL} alt="" />
            ) : (
              <img src={DefaultImage} alt="" />
            )}

            <div className="ms-3 m-1 me-0 p-2 w-100 bg-light-purple radius-5">
              <p className="answer-text">{text} </p>
              <div className="icon-div ">
                <div className="d-flex" onClick={createUpVote}>
                  {" "}
                  {upIcon ? (
                    <Icon icon="ph:arrow-fat-up-fill" />
                  ) : (
                    <Icon icon="ph:arrow-fat-up-light" />
                  )}{" "}
                  <span>{upVote}</span>
                </div>{" "}
                <div className="d-flex" onClick={createDownVote}>
                  {" "}
                  {downIcon ? (
                    <Icon icon="ph:arrow-fat-down-fill" className="ms-3" />
                  ) : (
                    <Icon icon="ph:arrow-fat-down-light" className="ms-3" />
                  )}{" "}
                  <span> {downVote}</span>
                </div>{" "}
                <Icon
                  icon="bxs:share"
                  onClick={() => setReplyComment(!replyComment)}
                  className="ms-3"
                />{" "}
                <span>{replies?.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="post">
        <div className="topwrap">
          <div className="userinfo pull-left">
            <div className="avatar">
              {userData && userData.photoURL ? (
                <img class="w-10 h-10 rounded" src={userData.photoURL} alt="" />
              ) : (
                <img class="w-10 h-10 rounded" src={DefaultImage} alt="" />
              )}
             
            </div>

            <div className="icons">
              <img src="images/icon3.jpg" alt="" />
              <img src="images/icon4.jpg" alt="" />
              <img src="images/icon5.jpg" alt="" />
              <img src="images/icon6.jpg" alt="" />
            </div>
          </div>

          <div className="posttext pull-left">
            {userData && (
              <div>
                <b> {`${userData?.firstName} ${userData?.lastName}`}</b>
              </div>
            )}

            <p>{text} </p>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="postinfobot">
          <div className="likeblock pull-left">
            <span
              style={{ cursor: "pointer" }}
              onClick={createUpVote}
              className="up"
            >
              <i className="fa fa-thumbs-o-up"></i>
              {upVote}
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={createDownVote}
              className="down"
            >
              <i className="fa fa-thumbs-o-down"></i>
              {downVote}
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setReplyComment(!replyComment)}
              className=" mx-3"
            >
              <i className="fa fa-reply" style={{ fontSize: "17px" }}></i>
            </span>
          </div>

          <div className="prev pull-left"></div>
          <div className="posted pull-left">
            <i className="fa fa-clock-o"></i> Posted on ht :{" "}
            {moment(createdAt).fromNow()}
          </div>

          <div className="next pull-right">
           
          </div>

          <div className="clearfix"></div>
        </div>
        {replyComment ? (
          <>
            <CommentForum
              refId={"reply"}
              answerId={replyId}
              getComment={getComment}
            />
            {replies.map((e) => {
              return (
                <DownReply
                  prevAnswerId={replyId}
                  replyId={e._id}
                  userId={e?.userId}
                  comment={e.comment}
                  createdAt={e.createdAt}
                />
              );
            })}
          </>
        ) : null}
      </div> */}
      {replyComment ? (
        <>

          {/* {replies.length <= 0 ? (
            <div>
              {" "}
              <MyLoader className="mt-3" />
              <MyLoader />
              <MyLoader />
            </div>
          ) : ( */}
          {replies.map((e) => {
            return (
              <DownReply
                prevAnswerId={replyId}
                replyId={e._id}
                userId={e?.userId}
                comment={e.comment}
                createdAt={e.createdAt}
              />
            );
          })
          }
          <div className="ms-5 tree_label_comment_3">
            {" "}
            <CommentForum
              refId={"reply"}
              answerId={replyId}
              getComment={getComment}
            />
          </div>
        </>
      ) : null}
    </>
  );
}
