import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import ProfileCard from "../../Components/Card/profile-card";
import SidebarProfile from "../../Components/Sidebar/SidebarProfile";
import Header from "../../Layout/Header";
import "../../Style/Custom/custom.css";
import "../../Style/style.css";
export default function Index() {
  const user = useSelector((state) => state.user);
  const historyCaluculate = []


  return (
    <div className="">
      <Header />
      <div className="container">
        <div className="d-flex pt-52">
          <div className="d-none d-lg-block" style={{ width: " 251px" }}>
            <SidebarProfile />
          </div>
          <div className="w-100 ms-lg-5 mt-3 ">
            <div className="bg-white p-3">
              <div className="row ">
                <div className="col-6 ">
                  {" "}
                  <h3 className="my-3">My Profile</h3>
                </div>
                <div className="col-6 ">
                  <Link to="/edit-profile">
                    {" "}
                    <div
                      className="btn-primary btn-outline "

                    >
                      Edit
                    </div>
                  </Link>
                </div>
              </div>

              <ProfileCard />
              <div className=" con-card p-3 mb-3 mt-3">
                <div className="row">
                  <div className=" col-12 ">
                    <h4>Personal Information</h4>
                  </div>
                  <div className="col-lg-4 col-12 py-lg-1">
                    <div className="mb-3">
                      <p>First Name</p>
                      <b>
                        {user?.firstName && <div>{`${user?.firstName}`}</div>}
                      </b>
                    </div>
                    <div className="mb-3">
                      <p>Email</p>
                      <b> {user?.email && <div>{`${user?.email} `}</div>}</b>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 py-lg-1">
                    <div className="mb-3">
                      <p>Last Name</p>
                      <b>
                        {" "}
                        {user?.lastName && <div>{`${user?.lastName}`}</div>}
                      </b>
                    </div>
                    <div className="mb-3">
                      <p>Phone</p>
                      <b>
                        {" "}
                        {user?.phoneNumber && (
                          <div>{`${user?.phoneNumber} `}</div>
                        )}
                      </b>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 py-lg-1">
                    <div className="mb-3">
                      <p>City, Country</p>
                      <b>
                        {user?.city && <>{`${user?.city} `}</>} ,{" "}
                        {user?.country && <>{`${user?.country} `}</>}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" con-card p-3 mb-3 mt-3">
                <div className="row">
                  <div className=" col-12 ">
                    <h4>Social Links</h4>
                  </div>
                  <div className="col-lg-4 col-12 py-lg-1">
                    <div className="mb-3">
                      <p>Facebook</p>
                      <b>{user?.facebook && <>{`${user?.facebook} `}</>}</b>
                    </div>
                    <div className="mb-3">
                      <p>Youtube</p>
                      <b>{user?.youtube && <>{`${user?.youtube} `}</>}</b>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 py-lg-1">
                    <div className="mb-3">
                      <p>Instagram</p>
                      <b>{user?.instagram && <>{`${user?.instagram} `}</>}</b>
                    </div>
                    <div className="mb-3">
                      <p>Twitter</p>
                      <b>{user?.twitter && <div>{`${user?.twitter} `}</div>}</b>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 py-lg-1">
                    <div className="mb-3">
                      <p>LinkedIn</p>
                      <b>{user?.linkedin && <>{`${user?.linkedin} `}</>}</b>
                    </div>
                    <div className="mb-3">
                      <p>Github</p>
                      <b>{user?.github && <>{`${user?.github} `}</>}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" con-card p-3  mt-3  mb-lg-0 mb-5 pb-lg-0 pb-2">
                <div className="row">
                  <div className=" col-12 ">
                    <h4>Website</h4>
                  </div>
                  <div className="col-lg-4 col-12 py-lg-1">
                    <div className="mb-3">
                      <p>Website</p>
                      <b>{user?.website && <>{`${user?.website} `}</>}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
