import React from "react";
import Category from "../../Components/Sidebar/CategorySidebar";
import WeeklyPull from "../../Components/Sidebar/WeeklyPull";
import Threads from "../../Components/Sidebar/Threads";
import { useSelector } from "react-redux";
export default function Index({ refId }) {
  const token = useSelector((state) => state.token);
  return (
    <div className="col-lg-4 col-md-4 my-3 ">
      {refId == "newcreate" ? token ? <Threads /> : null :  <Category />}
     
      {/* <WeeklyPull /> */}
      
    </div>
  );
}
