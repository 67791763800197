import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Post from "../../Layout/Post";
 import ContentLoader from "react-content-loader";
import { getRequest } from "../../Helper/httpProtocol";
import { mainCategories } from "../../Config/Consent";
import SidebarLeft from "../../Components/Sidebar/SidebarLeft";
import SidebarRight from "../../Components/Sidebar/SidebarRight";
export default function Index() {
  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="40" ry="40" width="40" height="40" />
      <rect x="80" y="17" rx="4" ry="4" width="200" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      <rect x="179" y="70" rx="3" ry="3" width="112" height="15" />
      <rect x="40" y="70" rx="1" ry="1" width="112" height="15" />
    </ContentLoader>
  );
  const [forum, setForum] = useState([]);
  const getForum = async () => {
    getRequest("forum/getcategorybyname", {
      category: mainCategories.filter((e) =>
        e.name
          .toLowerCase()
          .includes(
            window.location.pathname.split("/")[2].split("-")[0] ||
            window.location.pathname.split("/")[2].split("-")[1]
          )
      )[0].name,
    })
      .then((res) => {
        setForum(res.data.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getForum();
  }, []);
  return (
    <div>
      <Header />

      <div className="container ">
        <div className="row pt-52">
          <div className=" col-12 col-lg-3 ">
            <SidebarLeft />
          </div>
          <div className=" col-12 col-lg-6 edit-profile ">
            <div className=" p-3">
            
              {forum?.length <= 0 ? (
                <div>
                  {" "}
                  <MyLoader className="mt-3" />
                  <MyLoader className="mt-3" />
                  <MyLoader className="mt-3" />
                </div>
              ) : (
                <Post data={forum} />
              )}
            </div>
          </div>
          <div className=" col-12 col-lg-3 ">
            <SidebarRight />
          </div>
        </div>
      </div>
    </div>
  );
}

